//import { API } from 'aws-amplify';
import ngeohash from 'ngeohash';
import { useEffect, useRef, useState } from 'react';
/*
import {
  getDeal
} from '../../graphql/queries';
*/
import { MapSearch_Zoom_Radius } from '../constants/MapConstants';
// import { inStoreDealByStatesByAvailable_state_V2 } from './custom_queries';
// import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
// import {listMostInstoresByTimeApi} from '../../api/deal';
// import { listMostInstoresByTimeApi } from '../../api/deal';
import { useSearchParams } from 'react-router-dom';
import { listDealsByTimeApi } from '../../api/deal';
import { cleanAndLowercase } from '../utils/cleanStringForSearch';

export default function Map_v2_API_ByState({}) {
  // lock to prevent getListDeals from being accessed simultaneously (racing condition for nextToken)
  const lockRef = useRef(null);
  const nextTokenRef = useRef(null);
  const filterRef = useRef({});

  const [pageSize, setPageSize] = useState(300);
  const instoreString = 'instore'


  const [dealsInMostLocation, setDeals] = useState([]);
  const [filter, setFilter] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  // const [loading, setLoading] = useState(true);
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [DST,setDST] = useState(false);


  // const [dealsByState, setDealsByState] = useState([]);
  const [mostLoading, setMostLoading] = useState(true);
  /*
    New instore deal fetching
  */

  /* Initialize the filter from the url*/
  const parseSearchParameters = () => {
    // console.log('filter before parsings', filter);
    const cur_date = new Date();
    let jan = new Date(cur_date.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(cur_date.getFullYear(), 6, 1).getTimezoneOffset();
    let isDST = Math.max(jan, jul) != cur_date.getTimezoneOffset();
    setDST(isDST);
    const parsedFilter = {
      free_shipping: searchParameters.get('free_shipping'),
      promo_code_included: searchParameters.get('promo_code_included'),
      coupon_included: searchParameters.get('coupon_included'),
      // instore: searchParameters.get('instore'),
      price_min: searchParameters.get('price_min'),
      price_max: searchParameters.get('price_max'),
      dealer_types: searchParameters.getAll('dealer_types'),
      forum_types: searchParameters.getAll('forum_types'),
      sub_categories: searchParameters.getAll('sub_categories'),
      sort_by: searchParameters.get('sort_by'),
      q: searchParameters.getAll('q'),
    };
    // console.log('parsed searchParamters', parsedFilter);
    Object.entries(parsedFilter).forEach(([key, value]) => {
      if ((key === 'free_shipping') | (key === 'instore')) {
        if (value === null || value !== 'true') {
          parsedFilter[key] = false;
        } else {
          parsedFilter[key] = true;
        }
      } else if (key === 'promo_code_included') {
        if (value === null || value !== 'true') {
          parsedFilter[key] = false;
        } else {
          parsedFilter[key] = true;
        }
      } else if (key === 'coupon_included') {
        if (value === null || value !== 'true') {
          parsedFilter[key] = false;
        } else {
          parsedFilter[key] = true;
        }
      } else if ((key === 'price_min') | (key === 'price_max')) {
        if (value === null) {
          parsedFilter[key] = 0;
        } else {
          parsedFilter[key] = parseInt(value);
        }
      } else if ((key === 'dealer_types') | (key === 'forum_types')  | (key === 'sub_categories')) {
        if (
          !Array.isArray(value) ||
          value.every((entry) => typeof entry !== 'string')
        ) {
          parsedFilter[key] = [];
        } else {
          parsedFilter[key] = value;
        }
      } else if (key === 'sort_by') {
        if (
          (value === null) |
          ![
            'Most Recent',
            'Top Deals',
            'Percentage Off',
            'Trending Deals',
            //'Alphabetically',
            'Promo code included',
          ].includes(value)
        ) {
          parsedFilter[key] = 'Most Recent';
        } else {
          parsedFilter[key] = value;
        }
      } else if (key === 'q') {
        parsedFilter[key] = value;
      }
    });
    // console.log('parsedFilter: ', parsedFilter);
    setFilter(structuredClone(parsedFilter));
  };

  /*When filter are changed by the filter selector, update the url(search parameters)*/
  const updateSearchParameters = (filter) => {
    // console.log('search parameter before update', filter);
    Object.entries(filter).forEach(([key, value]) => {
      // free_shipping, instore, price_min, price_max, dealer_types, forum_types
      if (
        (value === false) |
        (value === 0) |
        (Array.isArray(value) && value.length === 0)
      ) {
        delete filter[key];
      }
    });
    setSearchParameters(filter);
    // console.log('updated search paramter', filter);
  };

  /*initialize the filter*/
  useEffect(() => parseSearchParameters(),
  []);

  /* use the initialized filter to get deals
    When filter are changed by the filter selector, update the url(search parameters)*/
  useEffect(() => {
    // console.log('changing into new filter', filter);
    filterRef.current = filter;
    const asyncFunction = async () => {
      updateSearchParameters(structuredClone(filter));
      await getDealsByState(false);
      setMostLoading(false);
    };

    if (Object.keys(filter).length > 0) {
      asyncFunction();
    }
  }, [filter]);

  useEffect(() => {
    parseSearchParameters();
  }, [searchParameters]);


  /*create filter query*/
  const createFilterQuery = () => {
    const {
      free_shipping,
      instore,
      promo_code_included,
      coupon_included,
      price_min,
      price_max,
      dealer_types,
      forum_types,
      sub_categories,
      q,
    } = filterRef.current;

    //console.log('Instore:', filterRef.current);
    // console.log('Instore forum:', forum_types);
    let filterQuery = {
      and: [
        {
          free_shipping: { ne: free_shipping === true ? false : null },
        },
        /*
        {
          instore: { eq: true },
        },
        */

        {
          dealType:{eq: instoreString}
        },

        {
          has_promotional_code:
            promo_code_included === true ? { eq: true } : { ne: null },
        },

        {
          has_coupon: coupon_included === true ? { eq: true } : { ne: null },
        },
      ],
    };

    if (!(price_min === price_max && price_min === 0)) {
      filterQuery.and.push({
        price: { ge: price_min },
      });
      filterQuery.and.push({
        price: { le: price_max },
      });
    }

    // console.log("forum_types:", forum_types);
    if (forum_types && forum_types.length > 0) {
      // console.log('1',filter);
      if (!filterQuery.or) {
        filterQuery.or = [];
      }
      filterQuery.or = filterQuery.or.concat(
        forum_types.map((forum_type) => ({
          forum_type: { contains: forum_type },
        }))
      );
    }
    // console.log("filterQuery1:", filterQuery);
    if (dealer_types && dealer_types.length > 0) {
      if (!filterQuery.and) {
        filterQuery.and = [];
      }
      let dealer_filters = [];
      dealer_filters = dealer_filters.concat(
        dealer_types.map((dealer_type) => ({
          dealer_type: { contains: dealer_type },
        }))
      );
      // console.log('dealer_filters', dealer_filters);
      filterQuery.and = filterQuery.and.concat({ or: dealer_filters });
    }

    if (sub_categories && sub_categories.length > 0) {
      if (!filterQuery.or) {
        filterQuery.or = [];
      }
      filterQuery.or = filterQuery.or.concat(
        sub_categories.map((sub) => ({
          sub_category: { contains: sub },
        }))
      );
    }

    if (q && q.length > 0) {
      filterQuery.and = filterQuery.and.concat(
        q.map((each_q) => ({
          or: [
            { title_lowercase: { contains: cleanAndLowercase(each_q) } },
            { description_lowercase: { contains: cleanAndLowercase(each_q) } },
            { forum_type_lowercase: { contains: cleanAndLowercase(each_q) } },
            { dealer_type_lowercase: { contains: cleanAndLowercase(each_q) } },
          ],
        }))
      );
    }
    // console.log('New Filter query:', filterQuery);
    return filterQuery;
  };

  /*Handle deal sorting*/
  /**
   * Handle sorting for price drop
   *
   * @param {bool} ascending - Whether the sorting is ascending or descending
   * @returns {bool} - if we're ascending, lower price_drop return -1. if we are descending, lower price_drop return 1.
   *
   * This method first check whether deal A and deal B have valid prev_price.
   * A valid prev_price means 1. have prev_price 2. prev_price > price.
   * If both A and B don't have valid prev_price, return 0;
   * If one of them have valid prev_price, it has larger price_drop
   * If both of the mhave valid prev_price, we compare which price_drop is larger
   *
   */
  function price_drop_alphabetically(ascending) {
    return function (a, b) {
      // nulls sort after anything else
      if (a.prev_price === null) {
        if (b.prev_price === null) {
          return 0;
        } else if (b.prev_price < b.price) {
          return 0;
        }
        return 1;
      }
      if (b.prev_price === null) {
        if (a.prev_price < a.price) {
          return 0;
        }
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      let a_price_drop = (a.prev_price - a.price) / a.prev_price;
      let b_price_drop = (b.prev_price - b.price) / b.prev_price;
      if (ascending) {
        return a_price_drop < b_price_drop ? -1 : 1;
      }

      // if descending, highest sorts first
      return a_price_drop < b_price_drop ? 1 : -1;
    };
  }
  /*Sort deals with promo code at top*/
  function promo_alphabetically(ascending) {
    return function (a, b) {
      // equal items sort equally
      if (a.promotional_code === b.promotional_code) {
        return 0;
      }

      // nulls sort after anything else
      if (a.promotional_code === null) {
        return 1;
      }
      if (b.promotional_code === null) {
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (ascending) {
        return a.promotional_code < b.promotional_code ? -1 : 1;
      }

      // if descending, highest sorts first
      return a.promotional_code < b.promotional_code ? 1 : -1;
    };
  }
  /*Sort the instore deals based on helper methods*/
  const sortDeals = (sort, filtered_instore_deals) => {
    // remove dup and sort
    setDeals((prevState) => {
      let prevStateClone;
      if(!filtered_instore_deals){
        prevStateClone = structuredClone(prevState);
      }
      else{
        prevStateClone = structuredClone(filtered_instore_deals);
      }
      // Remove duplicates
      prevStateClone = prevStateClone.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.id === current.id);
        if (x <= -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Sort
      if (sort === 'Alphabetically') {
        prevStateClone.sort((a, b) =>
          String(a.title).localeCompare(String(b.title))
        );
      }
      if (sort === 'Promo code included') {
        prevStateClone.sort(promo_alphabetically(false));
      }
      if (sort === 'Most Recent') {
        prevStateClone.sort((a, b) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
      }
      if (sort === 'Top Deals') {
        prevStateClone.sort(
          (a, b) => b.vote - b.down_vote - (a.vote - a.down_vote)
        );
      }
      if (sort === 'Percentage Off') {
        // prevStateClone.sort((a, b) => b.price_drop - a.price_drop);
        prevStateClone.sort(price_drop_alphabetically(false));
        // console.log(prevStateClone);
      }
      // if (sort === 'Trending Deals') {
      //   prevStateClone.sort((a, b) => b.trending_tag - a.trending_tag);
      // }
      // console.log('Sorted results:', prevStateClone);
      return prevStateClone;
    });
  };

  /*Helper method for sorting*/
  /*Calculate expiration time, posted 3 days ago 4am before will be removed*/
  const hoursDifferenceVal = (date) => {
    const past = new Date(date); // posted time according to user current TimeFrame
    const now = new Date(); // Current EST TIME
    //console.log('now',now);
    // console.log('Is DST:',DST);

    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    // console.log(hours);
    // console.log('hours: ',hours);
    if (hours >= 0 && hours < 72) { /// ! actual is 3
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }
    else{
      return 'deleted';
    }
  }
  /*Count number of filters selected*/
  const selectedFiltersCount = (filter) => {
    let count = 0;

    if (filter.promo_code_included === true) {
      count++;
    }
    if (filter.coupon_included === true) {
      count++;
    }
    if (filter.free_shipping === true) {
      count++;
    }
    if (filter.price_min > 0) {
      count++;
    }
    if (filter.price_max > 0) {
      count++;
    }

    count += filter.dealer_types?.length + filter.forum_types?.length + filter.sub_categories?.length;

    return count;
  };

  /*Fetch instore deals*/
  const getDealsByState = async (loadMore = true) => {
    try{
      if (lockRef.current) {
        // console.log('racing');
        return;
      }
      if (Object.keys(filterRef.current).length == 0) return;
      let filterQuery = createFilterQuery();
      lockRef.current = listDealsByTimeApi(
        filterQuery,
        filterRef.current.q && filterRef.current.q.length > 0 ? 1000 : pageSize,
        // pageSize,
        loadMore === true ? nextTokenRef.current : null
      );
      const data = await lockRef.current;
      // console.log('new data', data);

      let filtered_instore_deals = [];
      if (loadMore) {
        // dealsByDealTypeAndUpdatedAt
        data.data.dealsByDate.items.map(async (deal, indx) => {
          let expired_status = hoursDifferenceVal(deal.updatedAt);
          //console.log(expired_status);
          if (expired_status !== 'deleted') {
            filtered_instore_deals.push(deal);
          }
        });
        // console.log('loadmore setting deal:', filtered_instore_deals);
        await setDeals((prevDeals) => {
          let prevStateClone;
          prevStateClone = structuredClone(prevDeals);

          /*
          if(!prevDeals || (prevDeals && prevDeals.length == 0)){
            prevStateClone = structuredClone(dealsInMostLocation);
          }
          else{
            prevStateClone = structuredClone(prevDeals);
          }
          */

          // console.log('prev Deals:', prevStateClone);
          // console.log('new adding deals', filtered_instore_deals);
          let res = [...prevStateClone, ...filtered_instore_deals]
          // console.log('all instore deals',res);
          // return [...prevDeals, ...filtered_instore_deals]
          filtered_instore_deals = res;
          return res;
        });
      } else {
        data.data.dealsByDate.items.map(async (deal, indx) => {
          let expired_status = hoursDifferenceVal(deal.updatedAt);
          if (expired_status !== 'deleted') {
            filtered_instore_deals.push(deal);
          }
        });
        // console.log('not load more setting deal:', filtered_instore_deals);
        await setDeals(filtered_instore_deals);
      }

      nextTokenRef.current = data.data.dealsByDate.nextToken;
      // console.log('Current Data:', filtered_instore_deals);
      // console.log('Current filter:', filter);
      sortDeals(filter.sort_by, filtered_instore_deals);
      setSelectedCount(selectedFiltersCount(filter));
      lockRef.current = null;
    }catch (error) {
      // console.log(error);
      setDeals([]);
      lockRef.current = null;
    }
  };
  /**
   * Legacy code to fetch instore deals(most locations)
  */
  /*

  const getDealsByState = async (loadMore = true) => {
    try {
      if (lockRef.current) return [];
      lockRef.current= listMostInstoresByTimeApi(
        pageSize,
        loadMore === true ? nextTokenRef.current : null
      );
      const inStoreDealByStatesData = await lockRef.current;
      let inStoreDealByStates =
        inStoreDealByStatesData.data.inStoreDealByStatesByAvailable_state.items.filter(Boolean); // Add .filter(Boolean) here

      nextTokenRef.current = inStoreDealByStatesData.data.inStoreDealByStatesByAvailable_state.nextToken;
      // console.log(inStoreDealByStatesData.data.inStoreDealByStatesByAvailable_state);
      const promises = inStoreDealByStates
        .filter(deal => deal) // Add this line to filter out null deals
        .map((deal) =>
          API.graphql({
            query: getDeal,
            variables: {
              id: deal.deal_id,
              filter: {
                and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
              },
            },
          })
        );

      const results = await Promise.all(promises).then(results => results.filter(Boolean));

      let mostDealList = [];

      // console.log("inStoreDealByStates results: ", results);

      results.forEach((result, index) => {
        const deal = result.data.getDeal;
        // console.log("fetched deal: ", deal);
        if (deal) {
          mostDealList.push(deal);
        }
      });

      mostDealList.sort((a, b) =>
        String(b.updatedAt).localeCompare(String(a.updatedAt))
      );
      lockRef.current = null;
      setMostLoading(false);
      return mostDealList;

    } catch (err) {
      console.log('error', err);
      return [];
    }
  };
  */

  const getAllStoreNames = (deals) => {
    const storeNames = deals.map((deal) => deal.dealer_type);
    const uniqueStoreNames = [...new Set(storeNames)];
    return uniqueStoreNames;
  };

  const calculateRadius = (zoom) => {
    const radius = MapSearch_Zoom_Radius[zoom];
    // console.log(`zoom: ${zoom}, radius: ${radius}`);
    return radius;
  };

  const fetchGeoHashStoreDetails = async (
    myGeoHash,
    zoom,
    storeNames,
    storeName2DealIds
  ) => {
    if (!myGeoHash || !storeNames) {
      return [];
    }

    const { latitude, longitude } = ngeohash.decode(myGeoHash);
    const location = new window.google.maps.LatLng(latitude, longitude);
    const service = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );

    const fetchStoreDetails = (storeName) => {
      return new Promise((resolve) => {
        service.nearbySearch(
          {
            location,
            radius: calculateRadius(zoom),
            type: ['store'],
            keyword: storeName,
          },
          (results, status) => {
            // console.log('Nearby Search Results:', results);
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const stores = results.map((place) => {
                return {
                  nameFromMap: place.name,
                  storeName: storeName,
                  placeId: place.place_id,
                  rating: place.rating || '',
                  status: place.business_status
                    ? place.business_status === 'OPERATIONAL'
                      ? 'Open'
                      : 'Closed'
                    : '',
                  address: place.vicinity,
                  phone: place.formatted_phone_number || '',
                  geohash: ngeohash.encode(
                    place.geometry.location.lat(),
                    place.geometry.location.lng()
                  ),
                  geohash7: ngeohash
                    .encode(
                      place.geometry.location.lat(),
                      place.geometry.location.lng()
                    )
                    .slice(0, 7),
                  location: place.geometry.location,
                  closingTime: '',
                  openingTime: '',
                  dealIds: storeName2DealIds[storeName],
                  _type: 'ByState',
                };
              });
              resolve(stores);
            } else {
              resolve(null);
            }
          }
        );
      });
    };

    const storesPromises = storeNames.map(fetchStoreDetails);
    const stores = await Promise.all(storesPromises);
    const flatStores = stores.flat().filter(Boolean); // Use flat() to merge all arrays into one
    const noDupStores = Array.from(
      new Map(flatStores.map((store) => [store.placeId, store])).values()
    ); // Remove duplicates
    const validStores = noDupStores.filter((store) => {
      const nameFromMap = store.nameFromMap
        .replace(/[^a-zA-Z]/g, '')
        .toLowerCase();
      const storeName = store.storeName.replace(/[^a-zA-Z]/g, '').toLowerCase();

      // Calculate the difference in character count
      const charCountDiff = Math.abs(nameFromMap.length - storeName.length);

      return nameFromMap.includes(storeName) && charCountDiff <= 3;
    });
    return validStores;
  };

  const mergeNearbyResults = (stores) => {
    const seenGeohashes = {};
    const mergedStores = [];

    for (const store of stores) {
      const neighbors = ngeohash.neighbors(store.geohash7);

      if (
        seenGeohashes[store.geohash7] &&
        seenGeohashes[store.geohash7] === store.storeName
      ) {
        continue;
      }

      seenGeohashes[store.geohash7] = store.storeName;
      neighbors.forEach((neighbor) => {
        seenGeohashes[neighbor] = store.storeName;
      });

      mergedStores.push(store);
    }

    return mergedStores;
  };

  const generateStoreMarkers = (stores, storeName2DealIds) => {
    if (Object.keys(stores).length > 0) {
      const storeMarkers = Object.values(stores).map((store) => {
        if (store && store.geohash) {
          // Check if store and geohash are not undefined
          return {
            lat: ngeohash.decode(store.geohash).latitude,
            lng: ngeohash.decode(store.geohash).longitude,
            name: store.storeName,
            deals: store.num_of_deals,
            placeId: store.placeId,
            address: store.address,
            geohash: store.geohash,
            dealIds: storeName2DealIds[store.storeName],
          };
        }
      });
      // Filter out any undefined values
      return storeMarkers.filter(Boolean);
    }
    return [];
  };

  return {
    dealsInMostLocation,
    //storesByStateDetails,
    //storesByStateMarkers,
    //dealsByStateId2DetailsDict: dealId2DetailsDict,
    getDealsByState,
    nextTokenRef,
    mostLoading,
    setMostLoading, // filters
    sortDeals,
    filter,setFilter,
    selectedCount,
    selectedFiltersCount
  };
}
