import { ExpandLess, ExpandMore } from '@mui/icons-material';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import {
  Button,
  Checkbox,
  Collapse,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemButton,
  ListItemText,
  Slider,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import usePropState from '../../utils/usePropState';
import { SubCategoryKeyList, categoryList } from '../constants/CategoryConstants';

function MapMostFilter({ filter, setFilter, open, onClose, selectedFiltersCount }) {
  const [updatedFilter, setUpdatedFilter] = usePropState(filter);

  const brands = [
    'AT&T',
    'Ace Hardware',
    'Adidas',
    'Adorama',
    'Amazon',
    'AutoZone',
    'B&H Photo',
    "BJ's Wholesale Club",
    'Belk',
    'Best Buy',
    'Big Lots',
    'BrandsMartUSA',
    'CVS Pharmacy',
    'Champs Sports',
    'Costco',
    "Dick's Sporting Goods",
    'Foot Locker',
    'GAP',
    'GameStop',
    'IKEA',
    "JC Penney's",
    "Kohl's",
    "Lowe's",
    'Lululemon',
    "Macy's",
    'MicroCenter',
    'Neiman Marcus',
    'Nike',
    'Office Depot',
    'PC Richard & Son',
    'PetSmart',
    'Petco',
    "Sam's Club",
    'Sephora',
    'Shop My Exchange',
    'Shop Premium Outlets',
    'Staples',
    'Target',
    'The Home Depot',
    'Tractor Supply Co.',
    'Verizon',
    'Walgreens',
    'Walmart',
    'Wayfair',
    'Woot!',
    'Other',
    //
    "7-Eleven",
    "ABC WIN & Liquor",
    "Abercrombie & Fitch",
    "Academy Sports",
    "Advance Auto",
    "Aeropostale",
    "Albertson's",
    "Aldi",
    "Allbirds",
    "Alo Yoga",
    "AMC",
    "American Eagle Outfitters",
    "Anker",
    "Ann Taylor",
    "App Store",
    "ASOS",
    "Athleta",
    "ASUS Store",
    "Barnes & Nobles",
    "Bass Pro Shops",
    "Bath & Body Works",
    "Bealls",
    "Bealls Outlet",
    "Bebe",
    "Bissell",
    "Black & Decker",
    "Bloomingdale's",
    // "BrandsMart USA",
    "Brita",
    "Brooks Running",
    "Buckle",
    "Burberry",
    "Burlington",
    "Camping World",
    "Callaway Golf",
    "Calphalon",
    "Calvin Klein",
    "Casey's General Store",
    "Champ's",
    "Chewy.com",
    "Children's Place",
    "Clinique",
    "Coach Outlet",
    "Coleman",
    "Crocs",
    "Crate & Barrel",
    "Crutchfield",
    "Cuisinart",
    "Dell",
    "Dillard's",
    "Discount Tire",
    "Disney Store",
    "DSW",
    "DKNY",
    "Dollar General",
    "Dollar Tree",
    "DoorDash",
    "DTLR",
    "Dyson",
    "Eddie Bauer",
    "Elf Cosmetics",
    "Eufy",
    "Fabletics",
    "FinishLine",
    "Fred Meyer",
    "Free People",
    "Food Lion",
    // "Footlocker",
    "Giant Eagle",
    "GNC",
    "Godiva",
    "Golf Discount",
    "Google Store",
    "Groupon",
    "Gucci",
    "Guitar Center",
    "Hanes",
    "Harbor Freight Tools",
    "Hobby Lobby",
    "Hoka",
    "Hurley",
    "Hydroflask",
    "Hy Vee",
    "Instacart",
    "JBL",
    "J. Crew",
    "Joann's",
    "Jos. A Bank",
    "Journey's",
    "Jewel Osco",
    "Kate Spade",
    "Kendra Scott",
    "Kenneth Cole",
    "Keurig",
    "Kirkland's",
    "Kohler",
    "Lenovo",
    "Lids",
    "LG",
    "Loft",
    "Logitech",
    "Lounge Fly",
    "Marshall's",
    "Meijer",
    "Menard's",
    "Michael's",
    "Michael Kors",
    "Microsoft Store",
    "Nautica",
    "Navy Exchange (NEX)",
    "Nebraska Furniture Mart (NFM)",
    "Nespresso",
    "New Balance",
    "Newegg",
    "Nintendo",
    "Nordstrom Rack",
    "The North Face",
    "Olay",
    "Old Navy",
    "Ollie's",
    "On Running",
    "Oofos",
    "O'Reilly Auto Parts",
    "Pandora",
    "Patagonia",
    "Piggly Wiggly",
    "PlayStation Store",
    "Postmates",
    "Publix",
    "Puma",
    "Reebok",
    "Revolve",
    "Ring",
    "Rockport",
    "Safeway",
    "Saks Fifth Avenue",
    "Samsung",
    "Save-A-Lot",
    "Sierra",
    "Shark Clean",
    "Sherwin Williams",
    "Shoprite",
    "Skechers",
    "Soundcore",
    "Sperry",
    "Sprouts Farmers Market",
    "Stanley",
    "Stop N' Shop",
    "Sun Bum",
    "Sunglasses Hut",
    "TJ Maxx",
    "Total Wine",
    "Tory Burch",
    "Uber",
    "Uber Eats",
    "Under Armour",
    "Ulta Beauty",
    "Victoria's Secret",
    // "Verizon Wireless",
    "Von's",
    "Wawa",
    "Wegman's Food Market",
    "Xbox Store",
    "Zappos",
    "Zotac Store"
  ];

  const handleUpdateFilter = (key, value) => {
    setUpdatedFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubcategory = (key,value) => {
    // console.log('handling:', key, value);
    // console.log('before sub filter', filter);
    setUpdatedFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  };

  const handleOnClose = () => {
    // console.log('changed filter', updatedFilter);
    setFilter(updatedFilter);
    onClose();
  };

  return (
    <div>
      <Drawer
        open={open}
        onClose={handleOnClose}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '60%', md: '45%', lg: '35%' },
          },
        }}
      >
        <div className="tw-flex tw-items-center tw-px-8 tw-pt-8 tw-font-semibold tw-text-xl tw-gap-x-4">
          <div>
            <Button
              onClick={onClose}
              variant="contained"
              sx={{
                backgroundColor: '#ffffff',
                border: '1px solid #e5e7eb',
                boxShadow: 'none',
                padding: '3px',
                minWidth: '0px',
                '&:hover': {
                  backgroundColor: '#ffffff',
                  boxShadow: 'none',
                  opacity: '0.8',
                  transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 150ms',
                },
              }}
            >
              <KeyboardArrowLeftRoundedIcon sx={{ color: '#000000' }} />
            </Button>
          </div>
          {`Filters (${selectedFiltersCount(updatedFilter)} selected)`}
        </div>
        <hr className="tw-mb-0" />
        {/*
        <Accordian
          title={'Categories'}
          content={
            <Checkboxes
              options={categoryList}
              selectedOptions={updatedFilter.forum_types}
              type="forum_types"
              handleUpdateFilter={handleUpdateFilter}
            />
          }
        />
        */}

        <hr className="tw-my-0" />
        <AccordianSubCategory
          title={'Categories'}
          content={
            <CheckboxesV2
              options={SubCategoryKeyList}
              selectedOptions={updatedFilter.sub_categories}
              type="sub_categories"
              handleUpdateFilter={handleSubcategory}
              selectedCategories = {updatedFilter.forum_types}
            />
          }
        />

        <hr className="tw-my-0" />
        <Accordian
          title={'Brands'}
          content={
            <Checkboxes
              options={brands}
              selectedOptions={updatedFilter.dealer_types}
              type="dealer_types"
              handleUpdateFilter={handleUpdateFilter}
            />
          }
        />
        <hr className="tw-my-0" />
        <Accordian
          title={'Prices (Min-Max)'}
          content={
            <Price
              price_min={updatedFilter.price_min}
              price_max={updatedFilter.price_max}
              handleUpdateFilter={handleUpdateFilter}
            />
          }
        />
        <hr className="tw-my-0" />
        <Accordian
          title={'Delivery Requirements'}
          type=""
          content={
            <Delivery
              free_shipping={updatedFilter.free_shipping}
              //instore={updatedFilter.instore}
              handleUpdateFilter={handleUpdateFilter}
            />
          }
        />
        <hr className="tw-my-0" />
        <Accordian
          title={'Promotions'}
          type=""
          content={
            <Promotions
              promo_code_included={updatedFilter.promo_code_included}
              coupon_included_status = {updatedFilter.coupon_included}
              //instore={updatedFilter.instore}
              handleUpdateFilter={handleUpdateFilter}
            />
          }
        />
        <div className="tw-p-4">
          <Button fullWidth variant="contained" onClick={handleOnClose}>
            Apply
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

function Accordian({ title, content }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <div>
      <ListItemButton
        onClick={handleClick}
        style={{
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <ListItemText
          primary={
            <div className="tw-text-base md:tw-text-lg tw-font-semibold">
              {title}
            </div>
          }
        />
        {show ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse in={show} timeout="auto" unmountOnExit>
        <div className="tw-px-9 tw-pb-6">{content}</div>
      </Collapse>
    </div>
  );
}

function AccordianSubCategory({ title, content }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (
    <div>
      <ListItemButton
        onClick={handleClick}
        style={{
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <ListItemText
          primary={
            <div className="tw-text-base md:tw-text-lg tw-font-semibold">
              {title}
            </div>
          }
        />
        {show ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse in={show} timeout="auto" unmountOnExit>
        <div className="tw-px-2 tw-pb-2">{content}</div>
      </Collapse>
    </div>
  );
}

function Checkboxes({ options, selectedOptions, type, handleUpdateFilter }) {
  const [selectedOptions_, setSelectedOptions_] = useState([
    ...selectedOptions,
  ]);
  const [action, setAction] = useState(
    selectedOptions.length === options.length ? 'Clear All' : 'Select All'
  );

  const handleChange = (option) => {
    const checkedOptionsTemp = [...selectedOptions_];
    // console.log('before: ', checkedOptionsTemp);
    if (checkedOptionsTemp.indexOf(option) > -1) {
      checkedOptionsTemp.splice(checkedOptionsTemp.indexOf(option), 1);
    } else {
      checkedOptionsTemp.push(option);
    }

    setSelectedOptions_(checkedOptionsTemp);
    // console.log('after: ', checkedOptionsTemp);
    handleUpdateFilter(type, checkedOptionsTemp);
  };

  const handleAction = () => {
    let checkedOptionsTemp = [];
    if (action === 'Select All') {
      checkedOptionsTemp = [...options];
      setAction('Clear All');
    } else {
      setAction('Select All');
    }
    setSelectedOptions_(checkedOptionsTemp);
    handleUpdateFilter(type, checkedOptionsTemp);
  };


  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={action === 'Clear All'}
              onChange={handleAction}
            />
          }
          label={<div className="tw-text-sm md:tw-text-base">{action}</div>}
        />
        <div className="tw-ml-4 tw-flex tw-flex-col">
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleChange(option)}
                />
              }
              label={<div className="tw-text-sm md:tw-text-base">{option}</div>}
            />
          ))}
        </div>
      </FormGroup>
    </FormControl>
  );
}

/*
  Handle the Subcategory selection
  Handling the options now
*/
function CheckboxesV2({ options, selectedOptions, type, handleUpdateFilter, selectedCategories }) {
  const [selectedOptions_, setSelectedOptions_] = useState([
    ...selectedOptions,
  ]);

  const [selectedCategories_, setSelectedCategories_] = useState([
    ...selectedCategories,
  ]);

  let category_arr = [];
  categoryList.map((category, indx) => {
    category_arr.push([category, false]);
 });
  // const [categoryObjects, setCategoryObjects]= useState(Object.fromEntries(category_arr));
  // console.log(categoryObjects);

  /*TODO*/
  const [action, setAction] = useState(
    selectedOptions.length === options.length ? 'Clear All' : 'Select All'
  );

  const handleChange = (subcategory) => {
    const checkedOptionsTemp = [...selectedOptions_];
    //console.log('before: ', checkedOptionsTemp);
    if (checkedOptionsTemp.indexOf(subcategory) > -1) {
      checkedOptionsTemp.splice(checkedOptionsTemp.indexOf(subcategory), 1);
    } else {
      checkedOptionsTemp.push(subcategory);
    }

    setSelectedOptions_(checkedOptionsTemp);
    handleUpdateFilter(type, checkedOptionsTemp);
    // console.log('category status', categoryObjects);
    //console.log('after: ', checkedOptionsTemp);
    // console.log(subcategory + ' selected:', checkedOptionsTemp.includes(subcategory));
  };

  /*TODO*/
  const handleAction = (category) => { /*TODO*/
    // console.log(category);
    const checkedCategoriesTemp = [...selectedCategories_];
    const checkedSubTemp = [...selectedOptions_];
    if (checkedCategoriesTemp.indexOf(category) > -1) {
      // remove current category and all sub categories
      checkedCategoriesTemp.splice(checkedCategoriesTemp.indexOf(category), 1);
      SubCategoryKeyList[category].map((sub, indx) => {
        if (checkedSubTemp.indexOf(sub) > -1) {
          checkedSubTemp.splice(checkedSubTemp.indexOf(sub), 1);
        }
      });
    } else {
      // add category and all subcategories
      checkedCategoriesTemp.push(category);
      SubCategoryKeyList[category].map((sub, indx) => {
        if (checkedSubTemp.indexOf(sub) <= -1) {
          checkedSubTemp.push(sub);
        }
      });
    }
    // handleUpdateFilter("forum_types", checkedOptionsTemp);
    setSelectedCategories_(checkedCategoriesTemp);
    setSelectedOptions_(checkedSubTemp);
    handleUpdateFilter("forum_types",checkedCategoriesTemp);
    handleUpdateFilter(type,checkedSubTemp);

    // setCategoryObjects({...categoryObjects,[category]:false});

    // setCategoryObjects({...categoryObjects,[category]:true});

    // handleUpdateFilter("forum_types", checkedOptionsTemp);

    /*
    let checkedOptionsTemp = [];
    if (action === 'Select All') {
      checkedOptionsTemp = [...options[category]];
      setAction('Clear All');
    } else {
      setAction('Select All');
    }
    setSelectedOptions_(checkedOptionsTemp);
    handleUpdateFilter(type, checkedOptionsTemp);
    */

    // handleSubcategory(subcategory)
  };


  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      <FormGroup>
        <div className="tw-ml-1 tw-flex tw-flex-col">
        {
          Object.entries(options).map(([category, sub_list]) => (
            <Accordian
              title={category}
              content={
                <div className="tw-ml-1 tw-flex tw-flex-col">
                  <FormControlLabel
                    key={category + 'all'}
                    control={
                      <Checkbox
                        checked={selectedCategories.includes(category)} /*TODO categoryObjects[category]*/
                        onChange={() => handleAction(category)}
                      />
                    }
                    label={<div className="tw-text-sm md:tw-text-base">{'Select All'}</div>}
                  />
                  <div className="tw-ml-4 tw-flex tw-flex-col">
                    {sub_list.map((subcategory, index) => (
                      <FormControlLabel
                        key={subcategory + index}
                        control={
                          <Checkbox
                            checked={selectedOptions.includes(subcategory)}
                            onChange= {() => handleChange(subcategory)}
                          />
                        }
                        label={<div className="tw-text-sm md:tw-text-base">{subcategory}</div>}
                      />
                    ))}
                  </div>
                </div>
              }
            />
          ))
        }
        </div>
      </FormGroup>
    </FormControl>
  );
  //
}

function Price({ price_min, price_max, handleUpdateFilter }) {
  const [price, setPrice] = useState([price_min, price_max]);

  const handleSliderChange = (event, newValue) => {
    setPrice(newValue);
    // console.log(newValue);
    handleUpdateFilter('price_min', newValue[0]);
    handleUpdateFilter('price_max', newValue[1]);
  };

  const handleTextChange = (event) => {
    const key = event.target.name;
    const value = event.target.value !== '' ? event.target.value : 0;

    const priceTemp = [...price];
    if (key === 'price_min') {
      priceTemp[0] = value;
    }
    if (key === 'price_max') {
      priceTemp[1] = value;
    }

    setPrice(priceTemp);
    handleUpdateFilter(key, value);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Slider
        fullWidth
        getAriaLabel={() => 'Temperature range'}
        value={price}
        max={3000}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
      />
      <div className="tw-flex tw-gap-6">
        <TextField
          type="number"
          size="small"
          name="price_min"
          label="Min"
          defaultValue={0}
          value={price[0]}
          onChange={handleTextChange}
        />
        <TextField
          type="number"
          size="small"
          name="price_max"
          label="Max"
          defaultValue={0}
          value={price[1]}
          onChange={handleTextChange}
        />
      </div>
    </div>
  );
}

function Delivery({ free_shipping, handleUpdateFilter }) {
  const [delivery, setDelivery] = useState({
    free_shipping: free_shipping,
    // instore: instore,
  });

  const handleChange = (event) => {
    const key = event.target.name;
    setDelivery((prevState) => {
      handleUpdateFilter(key, !prevState[key]);
      return { ...prevState, [key]: !prevState[key] };
    });
  };

  return (
    <FormControl required component="fieldset" variant="standard" fullWidth>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="free_shipping"
              checked={delivery.free_shipping}
              onChange={handleChange}
            />
          }
          label={
            <div className="tw-text-sm md:tw-text-base">Free Shipping</div>
          }
        />
        {/*
        <FormControlLabel
          control={
            <Checkbox
              checked={delivery.instore}
              onChange={handleChange}
              name="instore"
            />
          }
          label={
            <div className="tw-text-sm md:tw-text-base">In-store Deals</div>
          }
        />
        */}
      </FormGroup>
    </FormControl>
  );
}

/**
   * Handle click event for Promotions in the filter
   *
   * @param {boolean} promo_code_included - Boolean to filter whether the deal has promotion code or not
   * @param {boolean} coupon_included_status - Boolean to filter whether the deal has coupon or not
   * @param {function}  handleUpdateFilter - Function to Update current filter
   * @returns {Object} the checkbox for promotions
   *
   * Update the filter whether the deal have promotion code or not
*/
function Promotions({ promo_code_included, coupon_included_status, handleUpdateFilter }) {
  const [promotions, setPromotions] = useState({
    promo_code_included: promo_code_included,
  });
  const [coupons, setCoupons] = useState({
    coupon_included: coupon_included_status,
  });

  const handleChange = (event) => {
    const key = event.target.name;
    setPromotions((prevState) => {
      handleUpdateFilter(key, !prevState[key]);
      return { ...prevState, [key]: !prevState[key] };
    });
  };

  const handleCoupon = (event) => {
    const key = event.target.name;
    // console.log(key);
    setCoupons((prevState) => {
      handleUpdateFilter(key, !prevState[key]);
      return { ...prevState, [key]: !prevState[key] };
    });
  };

  return (
    <FormControl required component="fieldset" variant="standard" fullWidth>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="promo_code_included"
              checked={promotions.promo_code_included}
              onChange={handleChange}
            />
          }
          label={
            <div className="tw-text-sm md:tw-text-base">Promo Code Included</div>
          }
        />
        {
        <FormControlLabel
          control={
            <Checkbox
              name="coupon_included"
              checked={coupons.coupon_included}
              onChange={handleCoupon}
            />
          }
          label={
            <div className="tw-text-sm md:tw-text-base">Coupon Included</div>
          }
        />
        }
      </FormGroup>
    </FormControl>
  );
}


export default MapMostFilter;