import { Auth, Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';

export async function signInWithGoogle() {
  try {
    const user = await Auth.federatedSignIn({ provider: 'Google' });
    console.log('Google Sign In Success:', user);
    return user;
  } catch (error) {
    console.error('Google Sign In Error:', error);
    throw error;
  }
}

export async function postLoginApi(user_name, password) {
  //await DataStore.clear();
  const user = await Auth.signIn(user_name, password);
  // await DataStore.start();

  return user;
}

export async function postRegisterApi(user_name, password, attributes) {
  const user = await Auth.signUp({
    username: user_name,
    password: password,
    attributes: attributes,
  });

  return user;
}

export async function postProfilePictureApi(imageName) {
  if (imageName !== '') {
    const cur_uuid = uuid();
    const { key } = await Storage.put(
      'profile-img/' + `${cur_uuid}` + '.png',
      imageName,
      { contentType: 'image/*' }
    );
    const REACT_APP_PROFILE_BUCKET_NAME =
      process.env.REACT_APP_PROFILE_BUCKET_NAME;

    return `https://${REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/${key}`;
  } else {
    return null;
  }
}

export async function postVerifySignupApi(user_name, authenticationCode) {
  await Auth.confirmSignUp(user_name, authenticationCode);
}

export async function postVerifySendCodeApi(user_name) {
  await Auth.resendSignUp(user_name);
}

export async function postResetPasswordApi(
  user_name,
  verificationCode,
  newPassword
) {
  await Auth.forgotPasswordSubmit(user_name, verificationCode, newPassword);
}

export async function postSendVerificationCodeApi(user_name) {
  await Auth.forgotPassword(user_name);
}

export async function getProfileImage(user_id) {
  const profileImageUrl = `https://${process.env.REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/profile-img/${user_id}.png`;

  try {
    new URL(profileImageUrl);
    if(user_id){
      // console.log('the url', profileImageUrl);
      const response = await fetch(profileImageUrl, { method: 'HEAD' });
      await response.text();
      if (response.ok) {
        //console.log('the url is valid');
        // `${ profileImageUrl}?${Date.now()}`
        return `${ profileImageUrl}?${Date.now()}`;
      } else {
        //console.log('the url is not valid');
        return '/blank-profile-picture.png';
      }
    }
    else{
      //console.log('no userid');
      return '/blank-profile-picture.png';
    }
    /*
    const response = await fetch(profileImageUrl, { method: 'HEAD' });
    if (response.ok) {
      return profileImageUrl;
    } else {
      return '/blank-profile-picture.png';
    }
    */
  } catch (err) {
    // console.log(err);
    return '/blank-profile-picture.png';
  }
}