import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppContext_ } from '../../AppContextProvider';
import {
  getBookmarkApi,
  postCreateBookmarkApi,
  postDeleteBookmarkApi,
} from '../../api/bookmark';


function useBookmark(deal_id) {
  const { user } = useAppContext_();
  const queryClient = useQueryClient();
  const queryKey = ['bookmark', deal_id];

  const {
    data: bookmark,
    isLoading,
    error,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      const response = await getBookmarkApi(user?.attributes?.sub, deal_id);
      if (response.length > 0) {
        return response[0];
      }
      return null;
    },
    enabled: user ? true : false,
    placeholderData: null,
    staleTime: 180000,
  });

  const createBookmark = useMutation({
    mutationFn: () => postCreateBookmarkApi(user?.attributes?.sub, deal_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });

  const deleteBookmark = useMutation({
    mutationFn: () => postDeleteBookmarkApi(bookmark),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey });
    },
  });

  const handleBookmark = async() => {
    if (bookmark) {
      await deleteBookmark.mutateAsync();
    }
    else {
      await createBookmark.mutateAsync();
    }
  }

  return {
    bookmark,
    handleBookmark,
  };
}

export default useBookmark;
