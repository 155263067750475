//import GridOnIcon from '@mui/icons-material/GridOn';
//import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
//import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import {
  //Box,
  //Button,
  //FormControl,
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { loadingDemoDataList } from '../../api/deal';
import usePropState from '../../utils/usePropState';
import useListBigDayDeals from '../apiHooks/useListBigDayDeals';
import Section from '../home/Section';
import BigDayPost from '../utils/BigDayPost';
import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
import { ReactComponent as BigDayIcon } from './BigDealDay-icon.svg';
//import Filter from './Filter';
//import FilterPills from './FilterPills';
import { ReactComponent as LightningHomeTime } from '../home/homeIcons/LightningHomeIcon1.svg';
import { ReactComponent as LightningHomeGift } from '../home/homeIcons/LightningHomeIcon2.svg';
import { ReactComponent as LightningHomeBell } from '../home/homeIcons/LightningHomeIcon3.svg';

function BigDeals() {
  const {
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    filter,
    setFilter,
    sortDeals,
    selectedCount,
    selectedFiltersCount,
    getListDeals,
    setPageSize,
  } = useListBigDayDeals();
  const [view, setView] = useState('grid');
  const [showFilter, setShowFilter] = useState(false);
  const [sortBy, setSortBy] = usePropState(filter.sort_by);

  const handleView = (event, value) => {
    setView(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showPosts = (items) => {
    return view === 'grid' ? (
      <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
          tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
      >
        {items.map((item, index) => (
          <BigDayPost item={item} orientation="vertical" />
        ))}
      </div>
    ) : (
      <Grid
        spacing={{ xs: 0.5, sm: 1, md: 2, lg: 3 }}
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
            <BigDayPost item={item} orientation="horizontal" />
          </Grid>
        ))}
      </Grid>
    );
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
    sortDeals(event.target.value);
    const filterCopy = structuredClone(filter);
    filterCopy.sort_by = event.target.value;
    setFilter(filterCopy);
  };

  if (isFetching) {
    return (
      <>
        <Section
          title="Big Deal Day"
          startIcon={
            <BigDayIcon/>
          }
          content={
            <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
              {/*
              <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <TuneRoundedIcon
                        sx={{
                          color: selectedCount > 0 ? '#3054F5' : 'black',
                        }}
                      />
                    }
                    size="large"
                    color="black"
                    sx={{
                      height: { xs: 'undefined', md: '3.75rem' },
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <div
                      className={`tw-whitespace-nowrap ${
                        selectedCount > 0
                          ? 'tw-text-string-primary tw-font-semibold'
                          : 'tw-text-string-base'
                      }`}
                    >{`Filters (${selectedCount})`}</div>
                  </Button>
                </div>
                <Box
                  sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                >
                  <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                    <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                      0 deals
                    </p>

                    <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                      <div className="tw-flex-1">
                        <FormControl fullWidth size="small">
                          <Select value={sortBy} onChange={handleSortBy}>
                            <MenuItem value={'Most Recent'}>
                              Most Recent
                            </MenuItem>
                            <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                            <MenuItem value={'Percentage Off'}>
                              Percentage Off
                            </MenuItem>
                            <MenuItem value={'Trending Deals'}>
                              Trending Deals
                            </MenuItem>
                            <MenuItem value={'Promo code included'}>
                              Promo code included
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <ToggleButtonGroup
                        size="small"
                        value={view}
                        exclusive
                        aria-label="view"
                        onChange={handleView}
                      >
                        <ToggleButton value="grid" aria-label="left aligned">
                          <GridOnIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="horizontal"
                          aria-label="left aligned"
                        >
                          <ViewHeadlineIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </Box>
                <Box
                  sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                >
                  <div className="tw-flex-1">
                    <FormControl fullWidth size="small">
                      <Select value={sortBy} onChange={handleSortBy}>
                        <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                        <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                        <MenuItem value={'Percentage Off'}>
                          Percentage Off
                        </MenuItem>
                        <MenuItem value={'Trending Deals'}>
                          Trending Deals
                        </MenuItem>
                        <MenuItem value={'Promo code included'}>
                          Promo code included
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Box>
              </div>
              */}
              {/*
              <FilterPills
                filter={filter}
                setFilter={setFilter}
                count={selectedFiltersCount(filter)}
              />
              */}
              <CustomPaginationEndlessMode
                items={loadingDemoDataList}
                component={(items) => showPosts(items)}
                fetchNextPage={fetchNextPage}
                hasMore={hasNextPage}
              />
            </div>
          }
        />
        {/*
        <Filter
          open={showFilter}
          filter={filter}
          onClose={() => setShowFilter(!showFilter)}
          setFilter={setFilter}
          selectedFiltersCount={selectedFiltersCount}
        />
        */}
      </>
    );
  }

  if (!isFetching && data && data.pages && data.pages[0].items.length > 0) {
    return (
      <div>
        <>
          <Section
            title="Big Deal Day"
            startIcon={
              <BigDayIcon/>
            }
            content={
              <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
                {/*
                <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                  <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={
                        <TuneRoundedIcon
                          sx={{
                            color: selectedCount > 0 ? '#398fed' : 'black',
                          }}
                        />
                      }
                      size="large"
                      color="black"
                      sx={{
                        height: { xs: 'undefined', md: '3.75rem' },
                      }}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <div
                        className={`tw-whitespace-nowrap ${
                          selectedCount > 0
                            ? 'tw-text-string-primary tw-font-semibold'
                            : 'tw-text-string-base'
                        }`}
                      >{`Filters (${selectedCount})`}</div>
                    </Button>
                  </div>
                  <Box
                    sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                  >
                    <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                      <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                        {data && data?.pages && data?.pages[0].items
                          ? data?.pages[0].items.length
                          : 0}{' '}
                        deals
                      </p>

                      <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                        <div className="tw-flex-1">
                          <FormControl fullWidth size="small">
                            <Select value={sortBy} onChange={handleSortBy}>
                              <MenuItem value={'Most Recent'}>
                                Most Recent
                              </MenuItem>
                              <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                              <MenuItem value={'Percentage Off'}>
                                Percentage Off
                              </MenuItem>
                              <MenuItem value={'Trending Deals'}>
                                Trending Deals
                              </MenuItem>
                              <MenuItem value={'Promo code included'}>
                                Promo code included
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <ToggleButtonGroup
                          size="small"
                          value={view}
                          exclusive
                          aria-label="view"
                          onChange={handleView}
                        >
                          <ToggleButton value="grid" aria-label="left aligned">
                            <GridOnIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="horizontal"
                            aria-label="left aligned"
                          >
                            <ViewHeadlineIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                  >
                    <div className="tw-flex-1">
                      <FormControl fullWidth size="small">
                        <Select value={sortBy} onChange={handleSortBy}>
                          <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                          <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                          <MenuItem value={'Percentage Off'}>
                            Percentage Off
                          </MenuItem>
                          <MenuItem value={'Trending Deals'}>
                            Trending Deals
                          </MenuItem>
                          <MenuItem value={'Promo code included'}>
                            Promo code included
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                </div>
                */}
                {/*
                <FilterPills
                  filter={filter}
                  setFilter={setFilter}
                  count={selectedFiltersCount(filter)}
                />
                */}
                <CustomPaginationEndlessMode
                  items={data?.pages[0]?.items}
                  component={(items) => showPosts(items)}
                  fetchNextPage={fetchNextPage}
                  hasMore={hasNextPage}
                />
              </div>
            }
          />
          {/*
          <Filter
            open={showFilter}
            filter={filter}
            onClose={() => setShowFilter(!showFilter)}
            setFilter={setFilter}
            selectedFiltersCount={selectedFiltersCount}
          />
          */}
        </>
      </div>
    );
  }
  if (!isFetching && data && data.pages && data.pages[0].items.length == 0){
    return (
      <div>
        <>
          <Section
            title="Big Deal Day"
            startIcon={
              <BigDayIcon/>
            }
            content={
              <div className='tw-w-full tw-flex tw-flex-1 tw-flex-col tw-justify-start tw-gap-y-2'>
                    <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <LightningHomeTime  className="tw-h-[32px] tw-w-[32px]"/>
                        <span className="tw-text-string-base">
                            Check back later for the <span className='tw-font-bold'>Target Circle</span> at <span className='tw-font-bold'>3 AM EST</span>
                        </span>
                    </div>
                    <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <LightningHomeGift  className="tw-h-[32px] tw-w-[32px]"/>
                        <span className="tw-text-string-base">
                            <span className='tw-font-bold'>Surprise drops</span> can happen <span className='tw-font-bold'>anytime</span>
                        </span>
                    </div>
                    <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <LightningHomeBell  className="tw-h-[32px] tw-w-[32px]"/>
                        <span className="tw-text-string-base">
                            <span className='tw-font-bold'>Check </span>
                            <a
                                className="tw-link-no-underline tw-text-string-primary tw-font-bold"
                                href="/deals"
                                // rel="external nofollow noopener"
                                // target="_blank"
                                >
                                Online deals
                            </a> for other available promotions
                        </span>
                    </div>
                </div>
            }
          />
        </>
      </div>
    );

  }
}

export default BigDeals;