import { useTheme } from '@emotion/react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { Dialog, IconButton, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';


function CustomCouponDialog({ open, onClose, title, description, content }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const contentRef = useRef();
  const [showDescription, setShowDescription] = useState(open);

  const handleScroll = (event) => {
    if (event.target.scrollTop <= 1) {
      setShowDescription(true);
    } else {
      setShowDescription(false);
    }
  };

  useEffect(() => setShowDescription(true), [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={false}
      PaperProps={{
        style: {
          height: 'auto',
          width: 'auto',
          borderRadius: isSmallScreen ? '0px' : '4px',
          margin: '0px',
          maxHeight: '100%',
          maxWidth: '100%',
        },
      }}
    >
      <div className="tw-relative">
        <div className="tw-overflow-auto tw-flex tw-flex-col tw-justify-between tw-h-window-coupon tw-w-full tw-h-window-coupon md:tw-w-[80vw] tw-h-window-coupon tw-w-window">
          <div>
            <div className="tw-p-4 md:tw-p-5 md:tw-pl-7 tw-m-0">
              <h1 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-m-0 tw-py-2">
                {title}
              </h1>
              <div className="tw-text-xl md:tw-text-2xl tw-py-2">
                  {description}
              </div>
              <div className="tw-text-xl md:tw-text-2xl tw-py-2">
                <span>Amazon Coupon Information -
                  <a
                    className="tw-text-string-primary"
                    href="https://amzn.to/3TEY5Cx"
                    rel="external nofollow noopener"
                    target="_blank"
                  >
                    https://amzn.to/3TEY5Cx
                  </a>
                </span>
              </div>
              <div className="tw-text-xl md:tw-text-2xl tw-py-2">
                <img className="tw-w-full" src="/coupon-example.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="tw-absolute tw-top-3 tw-right-3" onClick={onClose}>
          <IconButton size="large">
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
}

export default CustomCouponDialog;