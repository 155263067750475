import { Box } from "@mui/material";
import * as React from "react";
import { useEffect } from 'react';
import Map from "../map/Map";
import Banner from "./Banner";
import Brands from "./Brands";
import Categories from "./Categories";
import LightningHome from "./LightningHome";
import Section from "./Section";
import SectionBanner from "./SectionBanner";
// import SectionAmazon from './SectionAmazon';
import SectionBrands from "./SectionBrands";
import SectionInstore from "./SectionInstore";
import SectionLightning from "./SectionLightning";
//import SectionTarget from './SectionTarget';
// import SectionWalmart from './SectionWalmart';
import TopDeals from "./TopDeals";
// import TrendingPostGrid from "./TrendingPostGrid";
// import BigDealDayAmazon from './BigDealDayAmazon';
// import BigDealDayTarget from './BigDealDayTarget';
// import BigDealDayWalmart from './BigDealDayWalmart';

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div>
      <Box fullWidth>
        <SectionBanner content={<Banner />}/>
      </Box>
      {/*
      <SectionTarget
        name={"Oct 6-12 "}
        title={"Target Circle Week"}
        link={"/bigDealDay"}
        content={<BigDealDayTarget />}
      />
      */}

      {/*
      <SectionWalmart
        name={"Oct 8 at 12pm ET. (Walmart+ Members Receive Early Access Beginning at 12am ET)"}
        title={"Walmart Deals"}
        link={"/bigDealDay"}
        content={<BigDealDayWalmart />}
      />
      */}
      {/*
      <SectionAmazon
        name={"Oct 8-9"}
        title={"Amazon Prime Day"}
        link={"/bigDealDay"}
        content={<BigDealDayAmazon />}
      />
      */}

      <Section
        name={"This Week"}
        title={"Top Deals"}
        link={"/deals"}
        content={<TopDeals />}
      />

      <SectionLightning
        name={"Limited Time Offer"}
        title={"Lightning Deals"}
        link={"/lightning"}
        content={<LightningHome />}
      />

      <SectionInstore
        name={"In-Store Deals"}
        title={"Find In-Store Deals"}
        link={"/map"}
        content={<Map />}
      />

      <SectionBrands
        name={"Brands"}
        title={"Browse By Brands"}
        link={"/brands"}
        content={<Brands />}
      />

      <Section
        name={"Categories"}
        title={"Browse By Category"}
        link={""}
        content={<Categories />}
      />
      {/*
      <Section
        name={"Deals"}
        title={"Trending Nearby"}
        link={""}
        content={<TrendingPostGrid/>}
      />
      */}
    </div>
  );
}

export default Home;