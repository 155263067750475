import React, { useEffect, useState } from 'react';


import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { getDeal } from '../../graphql/queries';
import Map_v2_API from '../map/Map_v2_API';
import {
  listInStoreDealGeoHashesV2
} from './custom_queries';

// import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { loadingDemoDataList } from '../../api/deal'; // loadingDemoDataList
import VerticalPostInstoreGrid from '../home/VerticalPostInstoreGrid';

import { ReactComponent as LightningHomeTime } from '../home/homeIcons/LightningHomeIcon1.svg';
import { ReactComponent as LightningHomeGift } from '../home/homeIcons/LightningHomeIcon2.svg';
import { ReactComponent as LightningHomeBell } from '../home/homeIcons/LightningHomeIcon3.svg';


const options = {
  styles: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [{ saturation: -46 }, { lightness: -20 }],
    },
  ],
  gestureHandling: 'greedy',
};

const libraries = ['places'];

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function Map({ onAddressChange, storeName }) {
  // deals, dealsInMostLocation
  const {
    isLoaded,
    loadError,
    options,
    deals,
    storeDetails,
    geoHashStoreDetails,
    dealsInMostLocation,
    showPosts,
  } = Map_v2_API();
  const [combinedDeals, setCombinedDeals] = useState([]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  /*
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });
  */

  const [emptyDeals, setEmptyDeals] = useState([]);

  const [allDeals, setAllDeals] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [allStoreDetails, setAllStoreDetails] = useState([]);

  const navigate = useNavigate();

  const [myZipcode, setMyZipcode] = useState('');
  const [myGeoHash, setMyGeoHash] = useState('');
  const [selectedDealId, setSelectedDealId] = useState('');

  const defaultCenter = { lat: 39.8283, lng: -98.5795 }; // Center of the US
  const defaultZoom = 4; // Zoom level to show the entire US

  // Define state for the map center and zoom level
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  const [markers, setMarkers] = useState([]);
  const [dealId2DetailsDict, setDealId2DetailsDict] = useState({});

  const mapRef = React.useRef();
  const [mapLoaded, setMapLoaded] = useState(false);

  const [selectedMarkerPlaceId, setSelectedMarkerPlaceId] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const [storeDetailOpen, setStoreDetailOpen] = useState(false);

  const [mostDeals, setMostDeals] = useState();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    setMapLoaded(true);
  }, []);

  /*
  const handleSaveZipcodeToCognito = async (zipcode) => {
    if (zipcode && zipcode.length !== 5) {
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
        address: zipcode,
      });
      if (result === 'SUCCESS') {
        // // console.log("SUCCESS updating zipcode");
      }
    } catch (error) {
      // // console.log("Error updating zipcode: ", error);
    }
  };

  const fetchStoreDetails = (myStores) => {
    const promises = Object.values(myStores).map((store) => {
      return new Promise((resolve, reject) => {
        if (store.geohash) {
          // // console.log("fetching stores data");
          const { latitude, longitude } = ngeohash.decode(store.geohash);
          const location = new window.google.maps.LatLng(latitude, longitude);
          const service = new window.google.maps.places.PlacesService(
            document.createElement('div')
          );

          service.nearbySearch(
            {
              location,
              radius: 8, // 8 m
              type: ['store'],
              keyword: store.storeName,
            },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = results[0]; // Assuming the first result is the correct place
                // Get detailed information
                service.getDetails(
                  {
                    placeId: place.place_id,
                    fields: [
                      'name',
                      'rating',
                      'formatted_phone_number',
                      'opening_hours',
                    ],
                  },
                  (place, status) => {
                    if (
                      status ===
                      window.google.maps.places.PlacesServiceStatus.OK
                    ) {
                      store.rating = place.rating || ''; // Get the rating
                      store.status = place.opening_hours
                        ? place.opening_hours.open_now
                          ? 'Open'
                          : 'Closed'
                        : '';
                      store.phone = place.formatted_phone_number || '';
                      // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
                      const currentDayOfWeek = new Date().getDay();
                      // Assuming the store closes at the end of the current period
                      store.closingTime =
                        place.opening_hours &&
                        place.opening_hours.periods &&
                        place.opening_hours.periods[currentDayOfWeek]
                          ? place.opening_hours.periods[currentDayOfWeek].close
                              .time
                          : '';
                      // Assuming the store opens at the start of the current period
                      store.openingTime =
                        place.opening_hours &&
                        place.opening_hours.periods &&
                        place.opening_hours.periods[currentDayOfWeek]
                          ? place.opening_hours.periods[currentDayOfWeek].open
                              .time
                          : '';
                      // Add other data as needed
                      resolve(store);
                    } else {
                      resolve(store); // Resolve the promise even if status is not OK
                    }
                    // // console.log("fetched store data: ", place);
                  }
                );
              } else {
                resolve(store); // Resolve the promise even if status is not OK
              }
            }
          );
        } else {
          resolve(store); // Resolve the promise even if there is no geohash
        }
      });
    });
    return Promise.all(promises);
  };

  const fetchUrlZipcode = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlZipcode = urlParams.get('zipcode');
    if (urlZipcode) {
      setMyZipcode(urlZipcode);
      return true;
    }
    return false;
  };

  const fetchUserZipcode = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user?.attributes?.address) {
        setMyZipcode(user?.attributes?.address);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      if (!(await fetchUrlZipcode()) && !(await fetchUserZipcode())) {
        // fetchUserIp();
        setMapCenterByIpAddress();
      }
    })();
  }, []);

  const setMapCenterByZipcode = async (zipcode) => {
    let position;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: zipcode }, (results, status) => {
      if (status === 'OK') {
        // Check if the location is in the United States
        if (
          results[0].formatted_address.includes('USA') ||
          results[0].formatted_address.includes('United States')
        ) {
          position = results[0].geometry.location;
          setMyGeoHash(ngeohash.encode(position.lat(), position.lng()));
          setMapCenter({
            lat: position.lat(),
            lng: position.lng(),
          });
          setZoom(10); // Zoom level to show the area around the zipcode
        }
      }
    });
    return position;
  };

  const setMapCenterByIpAddress = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${REACT_APP_GOOGLE_MAP_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify({
            considerIp: true,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      const position = data.location;
      setMyGeoHash(ngeohash.encode(position.lat, position.lng));
      setMapCenter({
        lat: position.lat,
        lng: position.lng,
      });
      setZoom(10); // Zoom level to show the area around the IP address
    } catch (error) {
      // // console.error("Error:", error);
    }
  };


  const handleMap = async () => {
    alert('Coming soon. Thank you for using our website');
  };

  useEffect(() => {
    if (selectedMarkerPlaceId != null) {
      // // console.log(
      //   "Opening store detail dialog. StoreDetailPlaceId: ",
      //   selectedMarkerPlaceId
      // );
      setStoreDetailOpen(true);
    }
  }, [selectedMarkerPlaceId]);

  useEffect(() => {
    if (isLoaded) {
      (async () => {
        if (myZipcode && myZipcode.length == 5) {
          await setMapCenterByZipcode(myZipcode);
          handleSaveZipcodeToCognito(myZipcode);
        } else if (!myZipcode) {
          handleSaveZipcodeToCognito(myZipcode);
          setMapCenterByIpAddress();
        }
      })();
    }
  }, [myZipcode, isLoaded]);

  const fetchDealDetails = async (allDeals) => {
    try {
      // // console.log("allDeals: ", JSON.stringify(allDeals));
      const promises = allDeals.map((deal) =>
        API.graphql({
          query: getDeal,
          variables: {
            id: deal.deal_id,
            filter: {
              and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
            },
          },
        })
      );
      const results = await Promise.all(promises);
      // // console.log("dealDetails promises results: ", JSON.stringify(results));
      const dealDetails = {};
      results.forEach((result, index) => {
        // const deal = result.data.listDeals.items[0];
        const deal = result.data.getDeal;
        if (deal) {
          dealDetails[allDeals[index].deal_id] = deal;
        }
      });
      setDealId2DetailsDict(dealDetails);
      // // console.log("dealId2DetailsDict: ", JSON.stringify(dealId2DetailsDict));

      fetchStoreDetails(allStores).then((myStoreDetails) => {
        setAllStoreDetails(myStoreDetails);
        // // console.log("AllStoreDetails: ", myStoreDetails);
      });

      return dealDetails;
    } catch (error) {
      // // console.error("Error fetching deal details:", error);
    }
  };
  */
  const getMostInstoreDeals = async () => {
    try {
      let deal_id_set = new Set([]);
      const hashes = await API.graphql({
        // query: dealsByDate,
        query: listInStoreDealGeoHashesV2,
        // search_by_vote:"byVote",
        limit:50,
        filter: {
          and: [{ _deleted: { ne: true } }],
        },
      });
      let tempDeals = [];
      // console.log(tempDeals);
      hashes.data.listInStoreDealGeoHashes.items.map((deal, indx) => {
        try {
            if(deal_id_set.has(deal.deal_id) === false){
              tempDeals.push(deal);
              deal_id_set.add(deal.deal_id);
            }
        } catch(err) {
            console.log('error', err);
        }
      });
      const promises = tempDeals.map((deal) =>
          API.graphql({
            query: getDeal,
            variables: {
              id: deal.deal_id,
              filter: {
                and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
              },
            },
          })
      );
      const results = await Promise.all(promises);

      let mostDealList = [];

      //console.log(results);
      results.forEach((result, index) => {
        const deal = result.data.getDeal;
        //console.log(deal);
        if (deal) {
          mostDealList.push(deal);
        }
      });
      mostDealList.sort((a, b) =>
        String(b.updatedAt).localeCompare(String(a.updatedAt))
      );
      setMostDeals(mostDealList);

    } catch (err) {
      console.log('error', err);
    }
  };

  const getMostCombinedDeals = async () => {
    console.log(dealsInMostLocation);
    let combined = [...dealsInMostLocation];
    combined.concat(deals);
    console.log('combined',combined);
    combined.sort((a, b) => String(a.updatedAt).localeCompare(String(b.updatedAt)));
    console.log('sorted',combined);
  }
  useEffect(() => {
    getMostInstoreDeals();
  }, []);

  /*
  useEffect(() => {
    getMostCombinedDeals();
  }, []);

  useEffect(() => {
    getMostCombinedDeals();
  }, [isLoaded]);
  */

  /*
  useEffect(() => {
    if (markers && markers.length > 0) {
      (async () => {
        const myDealDetails = await fetchDealDetails(allDeals);
      })();
    }
  }, [allDeals]);
  */
  /*
  const fetchDealsByGeoHash = async (geohash4) => {
    try {
      // inStoreDealGeoHashesByGeohash4

      const deals = await API.graphql({
        query: inStoreDealGeoHashesByGeohash4V2,
        variables: {
          geohash4: geohash4,
          filter: {
            _deleted: { ne: true },
          },
        },
      });
      //console.log(geohash4, deals);
      // Check if the response has the expected structure
      if (
        deals.data &&
        deals.data.inStoreDealGeoHashesByGeohash4 &&
        deals.data.inStoreDealGeoHashesByGeohash4.items.length > 0
      ) {
        // console.log(geohash4, "found", deals.data.inStoreDealGeoHashesByGeohash4.items);
        return deals.data.inStoreDealGeoHashesByGeohash4.items;
      } else {
        //console.log('not found')
        //return { data: { inStoreDealGeoHashesByGeohash4: { items: [] } } }; // Return an object with the expected structure
        return [];
      }
    } catch (error) {
      console.error('Error fetching deals by geohash:', error);
      //return { data: { inStoreDealGeoHashesByGeohash4: { items: [] } } }; // Return an object with the expected structure
      return [];
    }
  };

  const sortByStores = (deals) => {
    return deals.reduce((stores, deal) => {
      if (!stores[deal.googlemap_placeid]) {
        stores[deal.googlemap_placeid] = {
          storeName: deal.store_name,
          address: deal.address,
          num_of_deals: 1,
          geohash: deal.geohash,
          placeId: deal.googlemap_placeid,
          dealIds: [deal.deal_id], // Add deal id to the new store
        };
      } else {
        stores[deal.googlemap_placeid].num_of_deals += 1;
        stores[deal.googlemap_placeid].dealIds.push(deal.deal_id); // Add deal id to the existing store
      }
      return stores;
    }, {});
  };

  const searchDealsByGeoHash = async () => {
    try {
      setMarkers([]);

      const geohash4 = myGeoHash.substring(0, 4);
      // console.log(`geohash4: ${geohash4}`);
      let area = ngeohash.neighbors(geohash4);
      // console.log(`Neighbors: ${neighbors}`);

      // Add the original geohash4 to the neighbors array
      area.unshift(geohash4);
      // console.log(`Updated Neighbors: ${area}`);

      let promises = area.map(fetchDealsByGeoHash);
      const results = await Promise.all(promises);

      const deals = [].concat(...results);
      // console.log(`Deals fetched: ${JSON.stringify(deals)}`);
      setAllDeals(deals);

      // console.log("filtered deals", deals);

      const stores = sortByStores(deals);
      // console.log(`Stores on Map: ${JSON.stringify(stores)}`);
      setAllStores(stores);

      if (Object.keys(stores).length > 0) {
        const storeMarkers = Object.values(stores).map((store) => ({
          lat: ngeohash.decode(store.geohash).latitude,
          lng: ngeohash.decode(store.geohash).longitude,
          name: store.storeName,
          deals: store.num_of_deals,
          placeId: store.placeId,
          address: store.address,
          geohash: store.geohash,
          dealIds: store.dealIds,
        }));
        // console.log(`Store Markers: ${JSON.stringify(storeMarkers)}`);
        setMarkers(storeMarkers);
      }
    } catch (error) {
      // console.error("Error fetching deals by geohash:", error);
    }
  };

  useEffect(() => {
    if (myGeoHash && myGeoHash.length > 3) {
      (async () => {
        await searchDealsByGeoHash();
      })();
    }
  }, [myGeoHash]);
  */

  if (loadError) return 'Error loading Instore deals';
  // if (!isLoaded) return 'Loading Maps';

  /*mostDeals && mostDeals.length >= 0 */
  return (
    <div >
      {isLoaded && dealsInMostLocation && dealsInMostLocation.length > 0 ?
        <VerticalPostInstoreGrid posts={dealsInMostLocation.slice(0,6)} />
        :
        <div className='tw-w-full tw-flex tw-flex-1 tw-flex-col tw-justify-start tw-gap-y-2'>
            <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                <LightningHomeTime  className="tw-h-[32px] tw-w-[32px]"/>
                <span className="tw-text-string-base">
                    Check back later for the <span className='tw-font-bold'>Instore deal</span> at <span className='tw-font-bold'>tomorrow</span>
                </span>
            </div>
            <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                <LightningHomeGift  className="tw-h-[32px] tw-w-[32px]"/>
                <span className="tw-text-string-base">
                    <span className='tw-font-bold'>Surprise drops</span> can happen <span className='tw-font-bold'>for every three days</span>
                </span>
            </div>
            <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                <LightningHomeBell  className="tw-h-[32px] tw-w-[32px]"/>
                <span className="tw-text-string-base">
                    <span className='tw-font-bold'>Check our </span>
                    <a
                        className="tw-link-no-underline tw-text-string-primary tw-font-bold"
                        href="/deals"
                        // rel="external nofollow noopener"
                        // target="_blank"
                        >
                        Online deals
                    </a> for other available promotions
                </span>
            </div>
        </div>
      }
    </div>
  );
}

export default Map;