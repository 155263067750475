import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import {
  Button,
  IconButton,
  ToggleButton,
  Tooltip,
  styled,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { BiSolidCoupon } from 'react-icons/bi';
import { FaRegShareSquare } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import useGraphql from '../../graphql/useGraphql';
import { ReactComponent as AmazonIcon } from '../../icons/AmazonBigDay_icon.svg';
import { ReactComponent as CalendarIcon } from '../../icons/CalendarBigDay_icon.svg';
import { ReactComponent as TargetIcon } from '../../icons/TargetBigDay_icon.svg';
import { ReactComponent as TruckIcon } from '../../icons/truck.svg';
import { ReactComponent as WalmartIcon } from '../../icons/WalmartBigDay_icon.svg';
import { storeIcon } from '../constants/StoreConstants';
import Share from '../dealDetails/Share';
import CustomDialog from './CustomDialog';

function BigDayPost({ item, orientation, type }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [bookmark, setBookmark] = useState(null);
  const [share, setShare] = useState(false);
  const { user } = useAppContext_();
  const [expired, setExpired] = useState(false);

  const pricePeriod = (price) => {
    if (!price) {
      return 0;
    } else if (price % 1 == 0) {
      return price;
    } else {
      return price.toFixed(2);
    }
  };

  const percentOff = (price, prevPrice) => {
    return Math.floor(((prevPrice - price) / prevPrice) * 100);
  };

  const daysDifference = (date) => {
    const past = new Date(date);
    const now = new Date();

    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (days < 7) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    } else {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    }
  };

  const hoursDifference = (date) => {
    const past = new Date(date);
    const now = new Date();

    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    // console.log(id, hours);
    /// ! actual is 3
    if (hours >= 0 && hours < 3) {
      /// ! actual is 3
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else {
      // console.log(id,'expired');
      return 'expired';
    }
  };

  const {
    title,
    price,
    prev_price,
    img_link,
    free_shipping,
    vote,
    down_vote,
    updatedAt,
    poster_name,
    id,
    promotional_code,
    dealer_type,
    dealType,
    isLightningDeal,
    isTopDeal,
    isTrendingDeal,
  } = item;
  const { createBookmarkApi, deleteBookmarkApi, getBookmarkApi } = useGraphql();

  /**
   * Handle click event for navigating to deal details.
   *
   * @param {Event} event - The click event.
   * @param {Object} item - The item representing the deal.
   * @returns {void}
   *
   * This onClick method is responsible for navigating to the details page of a deal item.
   * It checks if the click target is not an SVG element before navigating, allowing
   * other interactive elements like action icons (e.g., Like, Bookmark, Share) to function
   * without triggering the navigation.
   */
  const onClick = (event) => {
    if (
      event.target.nodeName !== 'svg' &&
      event.target.nodeName !== 'path' &&
      event.target.nodeName !== 'BUTTON'
    ) {
      if (location.pathname.startsWith('/deal-details/')) {
        navigate(`/deal-details/${id}`);
        window.location.reload(true);
      } else {
        navigate(`/deal-details/${id}`);
      }
    }
  };

  const ButtononClick = (event) => {
    navigate(`/deal-details/${id}`);
  };

  const backgroundColor = () => {
    if (dealer_type === 'Amazon') {
      return 'linear-gradient(90deg, #F56600 0%, #FF4D00 100%)';
    } else if (dealer_type === 'Target') {
      return 'linear-gradient(90deg, #FF0505 0%, #BB271A 100%)';
    } else {
      return 'linear-gradient(90deg, #0173E5 0%, #0C1F65 100%)';
    }
  };

  const cardTitle = () => {
    if (dealer_type === 'Amazon') {
      return 'Amazon Prime';
    } else if (dealer_type === 'Target') {
      return 'Target Circle Week';
    } else if (dealer_type === 'Walmart') {
      return 'Walmart Deals';
    } else{
      return 'Loading'
    }
  };

  const dayTitle = () => {
    if (dealer_type === 'Amazon') {
      return 'Oct 8-9 Only';
    } else if (dealer_type === 'Target') {
      return 'Oct 6-12 Only';
    } else if (dealer_type === 'Walmart') {
      return 'Oct 8 Only';
    } else{
      return 'Loading'
    }
  };

  const headerIcon = () => {
    if (dealer_type === 'Amazon') {
      return <AmazonIcon className="tw-text-string-neutral tw-bigday-icon-h tw-bigday-icon-w" />;
    } else if (dealer_type === 'Target') {
      return <TargetIcon className="tw-text-string-neutral tw-bigday-icon-h tw-bigday-icon-w" />;
    } else if (dealer_type === 'Walmart') {
      return <WalmartIcon className="tw-text-string-neutral tw-bigday-icon-h tw-bigday-icon-w" />;
    } else{
      return <AmazonIcon className="tw-text-string-neutral tw-bigday-icon-h tw-bigday-icon-w" />
    }
  };

  const handleShare = async () => setShare(!share);

  const handleAddBookmark = async () => {
    setBookmark(async (prevState) => {
      try {
        if (prevState === null) {
          setBookmark(await createBookmarkApi(id));
        } else {
          setBookmark(await deleteBookmarkApi(bookmark));
        }
      } catch (err) {
        alert('Oops! Too many clicks. Please try a few seconds later.');
      }
    });
  };

  useEffect(() => {
    const asyncFunction = async () => {
      let res = await getBookmarkApi(id, user?.attributes.sub);
      if (res) {
        setBookmark(res);
      } else {
        setBookmark(null);
      }
    };
    if (id && id !== '72010c76-8aaa-4f2f-9e28-74ac8e7db827') {
      asyncFunction();
    }
  }, []);

  useEffect(() => {
    const asyncFunction = async () => {
      let res = await getBookmarkApi(id, user?.attributes.sub);
      if (res) {
        // setBookmark(await getBookmarkApi(id,user?.attributes.sub));

        /*This section is duplicated with getHour so I commented it out*/
        /*
        if (isLightningDeal && isLightningDeal == 'true') {
          let expired_status = hoursDifference(updatedAt);
          if (expired_status == 'expired') {
            console.log(id, expired);
            await setExpired(true);
          }
        }
        */
        setBookmark(res);
      } else {
        setBookmark(null);
      }
    };

    const getHour = async () => {
      // console.log('testing expired');
      if (isLightningDeal && isLightningDeal == 'true') {
        // console.log(id, 'lightning Deals')
        let expired_status = hoursDifference(updatedAt);
        if (expired_status == 'expired') {
          await setExpired(true);
        }
        else{
          await setExpired(false);
        }
      }
      else{
        await setExpired(false);
      }
    };

    if (id && id !== '72010c76-8aaa-4f2f-9e28-74ac8e7db827') {
      asyncFunction();
      getHour();
    }
  }, [id]);

  const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#4c4c4c',
      color: 'rgb(255 255 255 / 98%)',
    },
  }));

  if (orientation === 'horizontal') {
    return (
      <div
        className={`tw-group tw-flex hover:tw-cursor-pointer hover:tw-shadow-md tw-transition-all tw-border tw-border-surface-border tw-rounded-md tw-bg-surface-raised tw-text-base tw-w-full tw-h-full
           ${
             isLightningDeal &&
             isLightningDeal == 'true' &&
             expired == true &&
             'tw-opacity-50'
           }`}
        onClick={onClick}
      >
        <div className="tw-flex tw-justify-center tw-relative tw-border-r tw-border-r-surface-border">
          <img
            src={img_link}
            className={`tw-p-8 tw-w-[150px] md:tw-w-[220px] tw-aspect-square tw-object-contain tw-max-w-none ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
            alt={title}
          />
          <span
            className={`tw-absolute tw-top-5 tw-left-4 ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
          >
            {!!prev_price && (
              <span className="tw-bg-surface-secondary tw-px-2 tw-pt-1 tw-pb-[2px] tw-rounded-sm tw-text-white tw-text-xs">
                {prev_price
                  ? `-${Math.floor(((prev_price - price) / prev_price) * 100)}%`
                  : ''}
              </span>
            )}
          </span>

          <span className="tw-absolute tw-top-2 tw-left-2 tw-z-50">
            {isLightningDeal &&
              isLightningDeal == 'true' &&
              expired == true && (
                <span
                  className={`tw-flex tw-gap-x-2 tw-bg-white tw-text-string-secondary tw-px-2 tw-pt-1 tw-pb-[2px]
                    tw-rounded-lg tw-text-xl tw-font-semibold
                    tw-justify-center tw-items-center tw-border-2 tw-border-surface-secondary`}
                >
                  <img src={'/expiredRed.svg'} className="tw-h-6 tw-w-6" />
                  EXPIRED
                </span>
              )}
          </span>

          <span
            className={`tw-absolute tw-top-3 tw-right-3 ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
          >
            <div>
              <ToggleButton
                size="small"
                value="left"
                aria-label="left aligned"
                className="!tw-bg-surface-base"
                onClick={handleAddBookmark}
              >
                {bookmark == null ? (
                  <BookmarkBorderOutlinedIcon
                    fontSize="small"
                    color="primary"
                  />
                ) : (
                  <BookmarkIcon fontSize="small" color="primary" />
                )}
              </ToggleButton>
            </div>
          </span>
          {isLightningDeal && isLightningDeal == 'true' && (
            <span
              className={`tw-flex tw-gap-x-1 tw-absolute tw-left-0 tw-bottom-0 tw-p-1 tw-pl-2 tw-text-[10px] md:tw-text-xs tw-font-semibold tw-rounded-tr-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine tw-text-black ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
              style={{
                background:
                  'linear-gradient(90deg, #FF0000 0%, #FFB200 50%, #FF0000 100%)',
                backgroundSize: '200% 100%',
              }}
            >
              {expired == true ? 'Lightning(Expired)' : 'Lightning'}
              <img src={'/LightningVector.png'} className="" />
            </span>
          )}
          {dealer_type && type !== 'Top Deal' && dealer_type in storeIcon && (
            <span
              className={`tw-h-8 tw-w-8 md:tw-h-11 md:tw-w-11 tw-bottom-0 tw-right-2 tw-rounded-full tw-overflow-hidden tw-absolute ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              <img
                src={storeIcon[dealer_type]}
                alt={dealer_type}
                className="tw-object-contain"
              />
            </span>
          )}
          {/*
             {(dealer_type && type !== 'Top Deal' && dealer_type in storeIcon === false) && (
              <span className="tw-absolute tw-bottom-2 tw-right-3">
                {dealer_type.length > 14?dealer_type.slice(0,14) + '...': dealer_type}
              </span>
            )}
          */}
        </div>

        <div
          className={`tw-px-4 md:tw-px-8 tw-py-4 tw-flex tw-flex-col tw-gap-y-4 tw-justify-start sm:tw-justify-between tw-text-string-base ${
            expired ? 'tw-opacity-50' : 'tw-opacity-100'
          }`}
        >
          <div className="tw-text-xs tw-font-medium tw-text-string-neutral">
            {poster_name} • {daysDifference(updatedAt)}
          </div>
          <div className="tw-text-sm md:tw-text-base tw-font-bold group-hover:tw-underline group-hover:tw-underline-offset-1">
            {title.length < 150 ? title + '\n' : title.slice(0, 150) + '...'}
          </div>
          <div className="tw-flex tw-items-center tw-gap-x-2">
            <span className="tw-text-string-base tw-text-base md:tw-text-2xl tw-font-bold group-hover:tw-underline">
              ${pricePeriod(price)}
            </span>
            {!!prev_price && (
              <span className="tw-text-sm md:tw-text-base tw-line-through tw-text-string-neutral">
                ${pricePeriod(prev_price)}
              </span>
            )}
          </div>
          <div className="tw-text-string-neutral tw-flex tw-items-center">
            {free_shipping && (
              <>
                <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                <span className="tw-text-xs md:tw-text-sm tw-whitespace-nowrap tw-underline tw-underline-offset-2">
                  Free Delivery
                </span>
              </>
            )}
          </div>
          <div className="tw-flex tw-text-string-primary tw-gap-4">
            {/*
              <span className="tw-flex tw-items-center tw-gap-x-1">
                <ChatBubbleOutlineRoundedIcon fontSize="small" />
                <span className="tw-text-xs md:tw-text-sm tw-whitespace-nowrap">
                  0 <span className="tw-hidden sm:tw-inline">reviews</span>
                </span>
              </span>
              */}
            <span className="tw-flex tw-items-center tw-gap-x-1">
              <ThumbUpOutlinedIcon fontSize="small" />
              <span className="tw-text-xs md:tw-text-sm">
                {vote + ' likes'}
              </span>
            </span>
            {
              <span className="tw-flex tw-items-center tw-gap-x-1">
                <ThumbDownOutlinedIcon fontSize="small" />
                <span className="tw-text-xs md:tw-text-sm tw-whitespace-nowrap">
                  {down_vote}
                  <span className="tw-hidden sm:tw-inline"> dislikes</span>
                </span>
              </span>
            }
          </div>

          <div className="tw-flex tw-gap-x-2">
            {!!promotional_code && (
              <div className="tw-text-string-neutral tw-py-1 tw-flex tw-items-center tw-gap-x-1">
                <BiSolidCoupon size={30} className="tw-text-string-primary" />
                <span className="tw-text-string-base tw-text-xs md:tw-text-sm">
                  Promo Code
                </span>
              </div>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              endIcon={<KeyboardArrowRightRoundedIcon />}
              onClick={ButtononClick}
            >
              See The Deals
            </Button>
            <ToggleButton
              size="small"
              value="left"
              aria-label="left aligned"
              onClick={handleShare}
            >
              <FaRegShareSquare size={20} className="tw-text-string-primary" />
            </ToggleButton>
          </div>
        </div>
        <CustomDialog
          open={share}
          onClose={handleShare}
          title={'Share Deal'}
          description={
            'Share this exciting deal with your friends, family, and community.'
          }
          content={
            <Share
              deal_link={
                item.affiliate_link ? item.affiliate_link : item.deal_link
              }
            />
          }
        />
      </div>
    );
  } else if (orientation === 'vertical') {
    //
    return (
      <div
        className={`tw-aspect-[9/16] lg:tw-aspect-[0.72/1]
          tw-flex tw-flex-col
          tw-rounded-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine
          tw-group hover:tw-cursor-pointer hover:tw-shadow-md
          tw-bg-surface-border
          ${type === 'Top Deal' && 'tw-bg-surface-border tw-p-[2px]'}
          ${type === 'Lightning Deal' && 'tw-bg-surface-border tw-p-[2px]'}
          ${
            type !== 'Lightning Deal' &&
            type === 'Top Deal' &&
            'tw-bg-surface-border tw-p-[1px]'
          }
          ${isLightningDeal && isLightningDeal == 'true'}
          ${
            type !== 'Top Deal' &&
            type !== 'Lightning Deal' &&
            'tw-bg-surface-border tw-p-[2px]'
          }
          `}
        style={{
          background: backgroundColor(),
          backgroundSize: '200% 100%',
        }}
        onClick={onClick}
      >
        <div className="tw-flex-1 tw-h-full tw-rounded-md tw-bg-surface-raised tw-flex tw-flex-col tw-divide-y tw-divide-surface-border">
          <div
            className={`tw-flex tw-text-[10px] md:tw-text-[11px] tw-leading-3 tw-items-center tw-pl-2 tw-pr-2 md:tw-pr-1 tw-py-[5px] tw-gap-x-1 tw-font-semibold tw-text-string-neutral ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
            style={{
              background: backgroundColor(),
              // backgroundSize: '200% 100%',
            }}
          >
            {/*<div className="tw-h-4 tw-w-4 md:tw-h-5 md:tw-w-5 tw-bg-gray-800 tw-rounded-full"></div>*/}
            <p className="tw-m-0 tw-flex tw-justify-between tw-w-full tw-font-semibold tw-text-string-white">
              <span className="tw-truncate tw-flex tw-items-center tw-bigday-time tw-bigday-title-lg">{headerIcon()}{cardTitle()}</span>
              <span className="tw-whitespace-nowrap tw-flex tw-gap-x-1 tw-bigday-time tw-items-center">
                <CalendarIcon/>{dayTitle()}
              </span>
            </p>
          </div>
          <div className="tw-flex tw-flex-1 tw-justify-center tw-relative tw-p-1 md:tw-p-2 tw-h-[60%] 2xl:tw-h-[55%]">
            <img
              src={img_link}
              className={`tw-aspect-square tw-flex-1 tw-object-contain ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
              alt={title}
            />
            <span
              className={`tw-absolute tw-top-3 tw-left-2 ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              {!!prev_price && (
                <span className="tw-bg-surface-secondary tw-px-2 tw-pt-1 tw-pb-[2px] tw-rounded-sm tw-text-white tw-text-xs">
                  {`-${percentOff(price, prev_price)}%`}
                </span>
              )}
            </span>

            <span className="tw-absolute tw-top-2 tw-left-2 tw-z-50">
              {isLightningDeal &&
                isLightningDeal == 'true' &&
                expired == true && (
                  <span
                    className={`tw-flex tw-gap-x-2 tw-bg-white tw-text-string-secondary tw-px-2 tw-pt-1 tw-pb-[2px]
                    tw-rounded-lg tw-text-xl tw-font-semibold
                    tw-justify-center tw-items-center tw-border-2 tw-border-surface-secondary`}
                  >
                    <img src={'/expiredRed.svg'} className="tw-h-6 tw-w-6" />
                    EXPIRED
                  </span>
                )}
            </span>

            <span
              className={`tw-absolute tw-top-2 tw-right-2 ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              <div>
                <ToggleButton
                  size="small"
                  value="left"
                  aria-label="left aligned"
                  className="!tw-bg-surface-base"
                  onClick={handleAddBookmark}
                >
                  {bookmark == null ? (
                    <BookmarkBorderOutlinedIcon
                      fontSize="small"
                      color="primary"
                    />
                  ) : (
                    <BookmarkIcon fontSize="small" color="primary" />
                  )}
                </ToggleButton>
              </div>
            </span>
            {type === 'Top Deal' && (
              <span
                className="tw-flex tw-gap-x-1 tw-absolute tw-left-0 tw-bottom-0 tw-p-1 tw-pl-2 tw-text-[10px] md:tw-text-xs tw-font-semibold tw-rounded-tr-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine tw-text-white"
                style={{
                  background:
                    'linear-gradient(90deg, #FF00A1 0%, #0D6EFD 50%, #FF00A1 100%)',
                  backgroundSize: '200% 100%',
                }}
              >
                Top Deal
                <img src={'/trophy.svg'} className="tw-grayscale tw-invert" />
              </span>
            )}
            {isLightningDeal && isLightningDeal == 'true' && (
              <span
                className={`tw-flex tw-gap-x-1 tw-absolute tw-left-0 tw-bottom-0 tw-p-1 tw-pl-2 tw-text-[10px] md:tw-text-xs tw-font-semibold tw-rounded-tr-md tw-bg-[length:200%_200%] tw-transition-all tw-animate-shine tw-text-black ${
                  expired ? 'tw-opacity-50' : 'tw-opacity-100'
                }`}
                style={{
                  background:
                    'linear-gradient(90deg, #FF0000 0%, #FFB200 50%, #FF0000 100%)',
                  backgroundSize: '200% 100%',
                }}
              >
                {expired == true ? 'Lightning(Expired)' : 'Lightning'}
                <img src={'/LightningVector.png'} className="" />
              </span>
            )}
            {/*
            <span
              className={`tw-absolute tw-bottom-1 tw-right-2 tw-text-[10px] md:tw-text-xs tw-flex tw-flex-col tw-items-end ${
                expired ? 'tw-opacity-50' : 'tw-opacity-100'
              }`}
            >
              {dealer_type in storeIcon ? (
                <div className="tw-h-8 tw-w-8 md:tw-h-11 md:tw-w-11 tw-rounded-full tw-overflow-hidden">
                  <img
                    src={storeIcon[dealer_type]}
                    alt={dealer_type}
                    className="tw-object-contain"
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="tw-font-semibold tw-bg-slate-50 tw-rounded-md tw-py-[1px] tw-px-[2px]">
                {dealer_type ? (
                  <>
                    {dealer_type.length > 15
                      ? dealer_type.slice(0, 15) + '...'
                      : dealer_type}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </span>
            */}
          </div>
          <div
            className={`tw-px-2 tw-pt-1 tw-p-1 md:tw-px-[10px] 2xl:tw-px-3 tw-flex tw-flex-col tw-justify-between tw-flex-1 ${
              expired ? 'tw-opacity-50' : 'tw-opacity-100'
            }`}
          >
            <div className="tw-flex tw-flex-col">
              <div className="tw-flex tw-items-center tw-gap-x-2">
                <span className="tw-text-lg tw-text-string-base md:tw-text-xl 2xl:tw-text-2xl tw-font-bold group-hover:tw-underline">
                  ${pricePeriod(price)}
                </span>
                {!!prev_price && (
                  <span className="tw-text-string-neutral tw-text-sm 2xl:tw-text-base tw-line-through tw-text-gray-500">
                    ${pricePeriod(prev_price)}
                  </span>
                )}
              </div>
              <DarkToolTip
                PopperProps={{
                  sx: {
                    popper: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: '#0000',
                      },
                    },
                  },
                }}
                title={title}
              >
                <div className="tw-text-string-base tw-text-sm 2xl:tw-text-base tw-font-bold group-hover:tw-underline group-hover:tw-underline-offset-1 tw-truncate">
                  {title}
                </div>
              </DarkToolTip>
              <div className="tw-flex tw-flex-1 tw-pt-1 tw-gap-x-3">
                {free_shipping && (
                  <div className="tw-w-full tw-flex tw-flex-1 tw-items-center tw-gap-x-1 tw-text-string-neutral">
                    <div className="tw-relative">
                      <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                      <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                        <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                      </div>
                    </div>
                    <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                      Free Delivery
                    </span>
                  </div>
                )}
                {(free_shipping === false || !!promotional_code) && (
                  <div className="tw-flex tw-flex-1 tw-gap-x-3">
                    {free_shipping == false && (
                      <div className=" tw-flex tw-flex-1 tw-items-center tw-gap-x-1 tw-text-string-neutral">
                        <div className="tw-relative">
                          <TruckIcon className="tw-text-string-neutral tw-h-[20px] tw-w-[20px]" />
                          <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                            <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                          </div>
                        </div>
                        <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                          Paid Delivery
                        </span>
                      </div>
                    )}
                    {!!promotional_code && (
                      <div className="tw-flex tw-flex-1 tw-justify-end md:tw-justify-start tw-items-center tw-gap-x-1 tw-text-string-neutral">
                        <div className="tw-relative">
                          <BiSolidCoupon size={20} className="md:tw-" />
                          <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                            <CheckCircleIcon className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[10px] !tw-h-[10px]" />
                          </div>
                        </div>
                        <span className="tw-text-[10px] md:tw-text-xs 2xl:tw-text-sm tw-whitespace-nowrap">
                          Promo
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="tw-flex-1 tw-flex tw-items-end">
              <div className="tw-flex tw-w-full tw-justify-between tw-items-center tw-text-string-neutral">
                <span className="tw-flex tw-items-center tw-gap-x-1">
                  {vote - down_vote < 0 ? (
                    <ThumbDownOutlinedIcon fontSize="small" />
                  ) : (
                    <ThumbUpOutlinedIcon fontSize="small" />
                  )}
                  {/* <ThumbUpOutlinedIcon fontSize="small" /> */}
                  <span className="tw-text-xs md:tw-text-sm">
                    {Math.abs(vote - down_vote)}
                  </span>
                </span>
                <IconButton fontSize="small" onClick={handleShare}>
                  <FaRegShareSquare
                    size={20}
                    className="tw-text-string-primary"
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <CustomDialog
          open={share}
          onClose={handleShare}
          title={'Share Deal'}
          description={
            'Share this exciting deal with your friends, family, and community.'
          }
          content={
            <Share
              deal_link={
                item.affiliate_link ? item.affiliate_link : item.deal_link
              }
            />
          }
        />
      </div>
    );
  }
}

export default BigDayPost;
