//import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import {
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    Switch,
    Typography,
} from '@mui/material';
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { loadingDemoDataList } from '../../api/deal';
import { useAppContext_ } from '../../AppContextProvider';
import { dealsByPoster_idAndUpdated_date } from '../../graphql/queries';
import Section from '../home/Section';
import VerticalPostGrid from './VerticalPostGrid';

function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
}

function PublicProfile(props) {
    const [imgUrl, setImgUrl] = useState();
    const [posts, setPosts] = useState([]);
    const [likes_count, setLikesCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAppContext_();
    const navigate = useNavigate();
    const { id,username} = useParams(); // profile user's id
    const { HomeCategoryValue, setHomeCategoryValue } = useAppContext_();

    const location = useLocation();
    const cur_username = username; // profile username
    const [hideExpired, setHideExpired] = useState(true);

    /*DST is used to identify Daylight saving time, used for removing expired Lightning deals*/
    const DST = (() => {
        const cur_date = new Date();
        let jan = new Date(cur_date.getFullYear(), 0, 1).getTimezoneOffset();
        let jul = new Date(cur_date.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) != cur_date.getTimezoneOffset();
    })();

    /*To filter out expired Lightning Deals.*/
    const hoursDifferenceVal = (date) => {
        const past = new Date(date); // posted time according to user current TimeFrame
        const now = new Date(); // Current EST TIME

        let threeAM;

        if (DST) {
        // + 4 UTC
            threeAM = new Date().setUTCHours(7, 0, 0, 0); // !!! actual is 7
            let threeAMDate = new Date(threeAM);
            if (threeAMDate.getDate() !== now.getDate()) {
                threeAM -= 24 * 60 * 60 * 1000;
            }
            threeAMDate = new Date(threeAM);
        } else {
        // + 5 UTC
            threeAM = new Date().setUTCHours(8, 0, 0, 0); // !!! actual is 8
            let threeAMDate = new Date(threeAM);
            if (threeAMDate.getDate() !== now.getDate()) {
                threeAM -= 24 * 60 * 60 * 1000;
            }
            threeAMDate = new Date(threeAM);
        }

        const secondsA = Math.floor((past - threeAM) / 1000);
        const minutesA = Math.floor(secondsA / 60);
        const hoursA = Math.floor(minutesA / 60);

        if (hoursA < 0) {
            return 'deleted';
        } else {
            const seconds = Math.floor((now - past) / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            // console.log('hours: ',hours);
            if (hours >= 0 && hours < 3) {
                /// ! actual is 3
                return `${hours} hour${hours === 1 ? '' : 's'} ago`;
            } else {
                return 'expired';
            }
        }
    };


     /**
     * Hide/ show the expired deals
     *
     * @param {}
     *
     * This method add/remove the expired check to the public profile page
    */
     const handleHideExpiredToggle = async () => {
        setHideExpired(!hideExpired);
    };

    /**
     * Fetch the public profile user img
     *
     * @param {userUuid} - user id of the public user profile
     *
     * This method fetch the profile picture of the user
    */
    const fetchProfileImgURL = async (userUuid) => {
        const REACT_APP_PROFILE_BUCKET_NAME = process.env.REACT_APP_PROFILE_BUCKET_NAME;
        // console.log(REACT_APP_PROFILE_BUCKET_NAME);
        let key = 'profile-img/'+ `${userUuid}` + '.png'
        const profilePicPathURI = `https://${REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/${key}`
        // console.log(profilePicPathURI);

        if(isValidUrl(profilePicPathURI)){
            await setImgUrl(profilePicPathURI);
        }

    };

    /**
     * Handle click event for navigating to deal details.
     *
     * @param {userUuid} - user id of the public user profile
     * @returns {prevStateClone} deals posted by the user sorted by time
     *
     * This method fetch the deals posted by a user and sorted by time(Descending).
    */
    const fetchDealList = async (userUuid) => {
        try {
            if(hideExpired == false){
                const dealData = await API.graphql({
                    query: dealsByPoster_idAndUpdated_date,
                    variables: {
                    poster_id: userUuid,
                    filter: {
                        and: [
                            {expired: { ne: true }}
                        ],
                        or: [
                        { _deleted: { attributeExists: false } },
                        { _deleted: { eq: false } },
                        ],
                    },
                    limit: 100,
                    sortDirection: 'DESC',
                    },
                    // authMode: AUTH_MODE.USER_POOLS,
                });
                let prevStateClone =  dealData.data.dealsByPoster_idAndUpdated_date.items;
                prevStateClone.sort((a, b) =>
                    String(b.updatedAt).localeCompare(String(a.updatedAt))
                );
                let likes_count = 0;

                // console.log(prevStateClone);
                for (let item of Object.values(prevStateClone)) {
                    likes_count = likes_count + item.vote;
                }
                return [prevStateClone, likes_count];
            }
            else{
                /*Need to remove expired Lightning Deals*/
                const dealData = await API.graphql({
                    query: dealsByPoster_idAndUpdated_date,
                    variables: {
                    poster_id: userUuid,
                    filter: {
                        and: [
                            {expired: { ne: true }}
                        ],
                        or: [
                        { _deleted: { attributeExists: false } },
                        { _deleted: { eq: false } },
                        ],
                    },
                    limit: 100,
                    sortDirection: 'DESC',
                    },
                    // authMode: AUTH_MODE.USER_POOLS,
                });
                let nonExpiredLightningDeals = [];
                dealData.data.dealsByPoster_idAndUpdated_date.items.map((deal, indx) => {
                    // console.log(deal);
                    if ( deal.isLightningDeal === 'true' || deal.dealType === 'lightning') {
                      // console.log(deal.id, ' is Lightning')
                      if (!['deleted', 'expired'].includes(hoursDifferenceVal(deal.updatedAt))) {
                        nonExpiredLightningDeals.push(deal);
                      }
                    }
                    else{
                      nonExpiredLightningDeals.push(deal);
                    }
                  })
                let prevStateClone = nonExpiredLightningDeals;
                prevStateClone.sort((a, b) =>
                    String(b.updatedAt).localeCompare(String(a.updatedAt))
                );
                let likes_count = 0;

                // console.log(prevStateClone);
                for (let item of Object.values(prevStateClone)) {
                    likes_count = likes_count + item.vote;
                }
                return [prevStateClone, likes_count];
                // return [[],0];
            }
        } catch (error) {
          console.error('Error fetching deal details: ', error);
          return [loadingDemoDataList, 0];
        }
    };

    const loadDealDetails = async () => {
        const [myDealList, likes_num] = await fetchDealList(id);
        setPosts(myDealList);
        setLikesCount(likes_num);
        setIsLoading(false);
    };

    useEffect(() => {
        // console.log(user);
        fetchProfileImgURL(id);
        loadDealDetails();
        setHomeCategoryValue();
    }, []);

    useEffect(() => {
        // console.log(user);
        setIsLoading(true);
        loadDealDetails();
    }, [hideExpired]);


    return (
        <div>
            {
            <div className="tw-flex tw-gap-6 tw-justify-center tw-items-center tw-py-4 tw-px-[3px]
                sm:tw-py-4 sm:tw-px-8
                md:tw-py-4 md:tw-px-16
                lg:tw-py-4 lg:tw-px-20
                xl:tw-py-8 xl:tw-px-24"
            >
                <div className="tw-flex tw-items-center tw-flex-col tw-w-full">
                    <Box sx={{display: { xs: 'none', sm: 'block'}, width: '100%' }}>
                        <div className="tw-text-string-base tw-bg-surface-raised tw-gap-x-4 tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border">
                            {isLoading === false && imgUrl? (
                                    <img
                                        src={imgUrl}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src="/blank-profile-picture.png";
                                        }}
                                        className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
                                    />
                                ) : (
                                <div className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover">
                                    <img
                                        src="/blank-profile-picture.png"
                                        className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
                                    />
                                </div>
                            )}
                             <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                                <div className="tw-text-string-base tw-bg-surface-raised tw-flex tw-flex-col tw-gap-y-10 tw-p-2 tw-px-8 tw-rounded-lg">
                                    <span className='tw-px-4 tw-text-xl tw-font-bold'><h3 className='tw-text-xl md:tw-text-3xl'>{cur_username?cur_username:'Anonymous User'}</h3></span>
                                    <div className = "tw-flex tw-flex-row tw-gap-x-3 tw-justify-center">
                                        <div className = "tw-flex tw-flex-row tw-px-4 tw-gap-x-2">
                                            <SaveOutlinedIcon sx={{ fontSize: '1.5rem', color: 'rgba(13, 110, 253, 1)'}} />
                                            <div >Post {isLoading ? 0 : posts?.length}</div>
                                        </div>
                                        <div className = "tw-flex tw-flex-row tw-px-4 tw-gap-x-2">
                                            <ThumbUpAltOutlinedIcon sx={{ fontSize: '1.5rem', color: 'rgba(13, 110, 253, 1)'}} />
                                            <div>Like {isLoading ? 0 : likes_count}</div>
                                        </div>
                                        {/*
                                        <div className = "tw-flex tw-flex-row tw-border tw-border-surface-border tw-rounded-lg tw-px-4 tw-gap-x-2 tw-bg-gray-400 tw-justify-center">
                                            <ChatOutlinedIcon sx={{ fontSize: '1.75rem', color: 'rgba(13, 110, 253, 1)'}} />
                                            Review {isLoading ? 0 : posts?.length}
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </p>
                        </div>
                    </Box>

                    <Box sx={{display: { xs: 'block', sm: 'none'}, width: '100%' }}>
                        <div className="tw-text-string-base tw-bg-surface-raised tw-gap-x-2 tw-flex tw-flex-1 tw-items-center tw-p-2 tw-px-2 tw-rounded-lg tw-border tw-border-surface-border">
                            {isLoading === false && imgUrl? (
                                    <img
                                        src={imgUrl}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src="/blank-profile-picture.png";
                                        }}
                                        className="tw-h-20 tw-w-20 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
                                    />
                                ) : (
                                <div className="tw-h-20 tw-w-20 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover">
                                    <img
                                        src="/blank-profile-picture.png"
                                        className="tw-h-20 tw-w-20 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
                                    />
                                </div>
                            )}
                             <p className="tw-w-[95%] tw-flex tw-items-center tw-m-0">
                                <div className="tw-text-string-base tw-bg-surface-raised tw-flex tw-flex-col tw-gap-y-6 tw-p-1 tw-px-4 tw-rounded-lg">
                                    <span className='tw-px-4 tw-text-xl tw-font-bold'><h3 className='tw-text-xl md:tw-text-3xl'>{cur_username?cur_username:'Anonymous User'}</h3></span>
                                    <div className = "tw-flex tw-flex-row tw-gap-x-2 tw-justify-center">
                                        <div className = "tw-flex tw-flex-row tw-px-2 tw-gap-x-2">
                                            <SaveOutlinedIcon sx={{ fontSize: '1.5rem', color: 'rgba(13, 110, 253, 1)'}} />
                                            <div >Post {isLoading ? 0 : posts?.length}</div>
                                        </div>
                                        <div className = "tw-flex tw-flex-row tw-px-2 tw-gap-x-2">
                                            <ThumbUpAltOutlinedIcon sx={{ fontSize: '1.5rem', color: 'rgba(13, 110, 253, 1)'}} />
                                            <div>Like {isLoading ? 0 : likes_count}</div>
                                        </div>
                                        {/*
                                        <div className = "tw-flex tw-flex-row tw-border tw-border-surface-border tw-rounded-lg tw-px-4 tw-gap-x-2 tw-bg-gray-400 tw-justify-center">
                                            <ChatOutlinedIcon sx={{ fontSize: '1.75rem', color: 'rgba(13, 110, 253, 1)'}} />
                                            Review {isLoading ? 0 : posts?.length}
                                        </div>
                                        */}
                                    </div>
                                </div>
                            </p>
                        </div>
                    </Box>
                </div>
            </div>
            }
            <Section
                // startIcon="/bookmark_heart.svg"
                title= {`${cur_username?cur_username:'Anonymous User'}'s Posts`}
                content={
                <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8  tw-w-full">
                    {/*
                    <div>
                        {isLoading === false && form.profilePicture? (
                        <img
                            src={`${user?.attributes?.picture}?${Date.now()}`}
                            className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
                        />
                        ) : (
                        <div className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover">
                            <PermIdentityOutlinedIcon sx={{ fontSize: '2.75rem' }} />
                        </div>
                        )}
                    </div>
                    */}

                    <div className="tw-flex tw-items-center tw-gap-x-4">
                        <Box sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}>
                            <div className="tw-text-string-base tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border">
                                <p className="tw-w-[65%] tw-flex tw-items-center tw-m-0">
                                    {isLoading ? 0 : posts?.length} posts
                                </p>
                                <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-end tw-items-center">
                                   <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Switch
                                                checked={hideExpired? true:false}
                                                onChange={handleHideExpiredToggle}
                                                name="gilad"
                                            />
                                            }
                                            label={
                                            <div
                                                className={`${
                                                hideExpired === false
                                                    ? 'tw-text-string-neutral'
                                                    : 'tw-text-string-primary'
                                                }`}
                                            >
                                                {hideExpired === false
                                                ? 'Show Expired'
                                                : 'Hide Expired'}
                                            </div>
                                            }
                                        />
                                    </FormGroup>
                                </FormControl>
                                </div>
                            </div>
                        </Box>
                        <Box
                            sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                        >
                            <div className="tw-text-string-base tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border">
                                <p className="tw-w-[35%] tw-flex tw-items-center tw-m-0">
                                    {isLoading ? 0 : posts?.length} posts
                                </p>
                                <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-end tw-items-center">
                                   <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                            <Switch
                                                checked={hideExpired? true:false}
                                                onChange={handleHideExpiredToggle}
                                                name="gilad"
                                            />
                                            }
                                            label={
                                            <div
                                                className={`${
                                                hideExpired === false
                                                    ? 'tw-text-string-neutral'
                                                    : 'tw-text-string-primary'
                                                }`}
                                            >
                                                {hideExpired === false
                                                ? 'Show Expired'
                                                : 'Hide Expired'}
                                            </div>
                                            }
                                        />
                                    </FormGroup>
                                </FormControl>
                                </div>
                            </div>
                        </Box>
                    </div>

                    <>

                        {isLoading == true? (
                            <>
                                {hideExpired === false ?(
                                    <VerticalPostGrid posts={loadingDemoDataList} />
                                    ):(
                                    <VerticalPostGrid posts={loadingDemoDataList} />
                                )}
                            </>
                        ) : (
                            <>
                            {hideExpired === false ?(
                                <VerticalPostGrid posts={posts} />
                                ):(
                                <VerticalPostGrid posts={posts} />
                            )}
                        </>
                        )}
                    </>
                    <Typography
                        variant="body1"
                        sx={{ mt: 2, mb: 8, textAlign: 'center' }}
                        className='tw-text-string-neutral'
                    >
                    {'We are displaying the most 100 recent deals shared by the user'}
                    <br />
                    {'for more, please use the filter to search on the Deals pages'}
                    <br />
                    </Typography>
                </div>
                }
            />
        </div>
    );
}
export default PublicProfile;