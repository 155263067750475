import * as React from 'react';
import { useState } from 'react';
import DeskTopNotification from './DeskTopNotification';
import OnWebNotification from './OnWebNotification';

export function NotificationHandler({
  notificationContent,
  openNotification,
  setOpenNotification,
  desktopPermission,
}) {
  const [openOnWebNotification, setOpenOnWebNotification] = useState(false);
  const [openDeskTopNotification, setOpenDeskTopNotification] = useState(false);

  React.useEffect(() => {
    // console.log('openNotification: ', openNotification);
    if (openNotification === true) {
      handleNotification();
      setOpenNotification(false);
    }
  }, [openNotification, setOpenNotification]);

  const handleNotification = () => {
    var audio = new Audio('audio/notification-1.mp3');
    // audio.play();
    setOpenOnWebNotification(true);
    setOpenDeskTopNotification(true);
  };

  return (
    <>
      <OnWebNotification
        notificationContent={notificationContent}
        openOnWebNotification={openOnWebNotification}
        setOpenOnWebNotification={setOpenOnWebNotification}
      />
      {desktopPermission ? (
        <DeskTopNotification
          notificationContent={notificationContent}
          openDeskTopNotification={openDeskTopNotification}
          setOpenDeskTopNotification={setOpenDeskTopNotification}
        />
      ) : (
        <></>
      )}
    </>
  );
}
