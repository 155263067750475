import { useQuery } from "@tanstack/react-query";
import { getProfileImage } from "../../api/account";

function useProfileImage(userId) {
  const { data: profileImage } = useQuery({
    queryKey: ['profileImage', userId],
    queryFn: () => getProfileImage(userId),
    placeholderData: '/blank-profile-picture.png',
    staleTime: 180000,
  });

  return {
    profileImage
  }
}

export default useProfileImage;