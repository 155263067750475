import { API } from "aws-amplify";
import { bookmarksByUser_id } from "../graphql/queries";
import { AUTH_MODE } from "../components/constants/AppSyncAuthMode";
import { createBookmark, deleteBookmark } from "../graphql/mutations";

export async function getBookmarkApi(username, deal_id) {
  const bookmark = await API.graphql({
      query: bookmarksByUser_id,
      variables: {
        user_id: username,
        filter: {
          _deleted: { ne: true },
          deal_id: { eq: deal_id },
        },
      },
      authMode: AUTH_MODE.USER_POOLS,
    });
  
  return bookmark.data.bookmarksByUser_id.items;
}

export async function postCreateBookmarkApi(username, deal_id) {
  const bookmark = await API.graphql({
    query: createBookmark,
    variables: {
      input: {
        user_id: username,
        deal_id: deal_id,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return bookmark.data.createBookmark
}

export async function postDeleteBookmarkApi(bookmark) {
  const { id, _version } = bookmark;
  const response = await API.graphql({
    query: deleteBookmark,
    variables: {
      input: {
        id: id,
        _version: _version,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return response.data.deleteBookmark;
}