import * as React from 'react';
import Post from '../utils/Post';
/*used in multiple classes*/
function VerticalPostInstoreGrid({ posts }) {
  return (
    <div className="tw-w-full">
      <div
        className="tw-grid-row-single tw-grid tw-gap-1
                     tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
      >
        {posts.map((item, index) => (
          <Post key = {index} item={item} orientation="vertical"/>
        ))}
      </div>
    </div>
  );
}

export default VerticalPostInstoreGrid;
