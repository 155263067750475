import { Link, createSearchParams } from 'react-router-dom';

function Categories() {
  const categories = [
    {
      name: 'Electronics',
      search_key: 'Electronics',
      description: 'Computers & Accessories, Televisions, Wearable Technology, and More!',
      icon: '/electro.png',
    },
    {
      name: 'Beauty & Personal Care',
      search_key: "Beauty and Personal Care",
      description: 'Fragrances, Hair Care, Skin Care, and More!',
      icon: '/beauty_health.png',
    },
    {
      name: 'Clothing, Shoes, & Jewelry',
      search_key: "Clothing, Shoes, and Jewelry",
      description: "Mens Clothing, Women's Clothing, Wallets, and More!",
      icon: '/clothing.png',
    },
    {
      name: 'Patio, Lawn, & Garden',
      search_key: "Patio, Lawn, and Garden",
      description: 'Outdoor Furniture, Patio Heaters, Lawn Mowers & Tractors, and More!',
      icon: '/patio.png',
    },
    {
      name: 'Home & Kitchen',
      search_key: "Home and Kitchen",
      description: 'Furniture, Bath Products, Bedding Products, and More!',
      icon: '/home.png',
    },
    {
      name: 'Toys & Games',
      search_key: "Toys and Games",
      description: 'Building Toys, Puzzles, Outdoor Play, and More!',
      icon: '/toys_icon.png',
    },
    {
      name: 'Tools & Home Improvement',
      search_key: "Tools and Home Improvement",
      description: 'Building Supplies, Hardware, Power Tools, and More!',
      icon: '/tools3.jpg',
    },
    {
      name: 'Automotive',
      search_key: "Automotive",
      description: 'Car Care, Interior Accessories, Vehicle Equipment, and More!',
      icon: '/automotives1.png',
    },
  ];
  return (
    <div className="tw-flex tw-w-full">
      <div className="tw-flex-1 tw-flex tw-flex-wrap">
        {categories.map((entry, index) => (
          <Link
            //to="/categoryForum"
            // state={{name:entry.name}}
            to={{
              pathname: '/deals',
              search: createSearchParams({
                forum_types: [entry.search_key],
              }).toString(),
            }}
            key={index}
            className="tw-bg-surface-raised tw-ring-1 tw-ring-surface-border tw-flex-shrink tw-w-1/2 md:tw-w-1/4 tw-p-2 tw-text-string-base tw-no-underline hover:tw-bg-surface-base tw-transition-all"
          >
            <p className="tw-text-sm md:tw-text-xl tw-font-bold tw-pb-2">
              {entry.name}
            </p>
            <div className="tw-flex">
              <p className="tw-text-xs md:tw-text-sm tw-text-string-neutral tw-flex-1">
                {entry.description}
              </p>
              <div className="tw-h-8 tw-w-8 md:tw-h-12 md:tw-w-12 tw-rounded-full tw-overflow-hidden">
                 <img
                  src={entry.icon}
                  className="tw-object-contain"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Categories;
