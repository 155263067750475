import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { API, Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import { getDeal } from '../../graphql/queries';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';
import { DealEditorAddressSelector } from './DealEditorAddressSelector';
import { dealCreate, dealInit, dealUpdate, deleteDeal } from './DealEditorAPIs';
import { DealEditorCheckBoxes } from './DealEditorCheckBoxes';
import { DealEditorDealLabel } from './DealEditorDealLabel';
import { DealEditorDialog } from './DealEditorDialog';
import { DealEditorImgUploader } from './DealEditorImgUploader';
import { DealEditorSocalManager } from './DealEditorSocalManager';
import { DealEditorSocalTemplate } from './DealEditorSocalTemplate';
import { DealEditorStepper } from './DealEditorStepper';
import { DealEditorStoreCategory } from './DealEditorStoreCategory';
import { DealEditorSwitches } from './DealEditorSwitches';
import { DealEditorTextField } from './DealEditorTextField';

function DealEditorIndex(props) {
  const navigate = useNavigate();
  const [dealDetails, setDealDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dealDetailsId, setDealDetailsId] = useState(null);
  const [userUuid, setUserUuid] = useState(null);
  const [dialogInfo, setDialogInfo] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);
  // const [inStorePickup, setInStorePickup] = useState(false);
  const [completedSteps, setCompletedSteps] = React.useState({});
  const [selectedKindOfDealOption, setSelectedKindOfDealOption] =
    useState('online');
  const [disabledSubmit, setDisabledSubmit] = React.useState(true);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [isAdmin, setIsAdmin] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = user.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('admin')) {
          setIsAdmin(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const { user, setUser, HomeCategoryValue, setHomeCategoryValue } =
    useAppContext_();

  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      var dealDetailsid = params.get('id');
      if (!dealDetailsid || dealDetailsid === '') {
        initEmptyDeal(false);
        setIsLoading(false);
      } else {
        setDealDetailsId(dealDetailsid);
        await loadDealDetails(dealDetailsid);
      }

      setIsLoading(false);
    };
    if (user) {
      fetchData();
      setHomeCategoryValue();
      window.scrollTo(0, 0);
    } else {
      navigate('/login');
    }
  }, []);

  const handleOpenDialog = (info) => {
    setDialogInfo(info);
  };

  const loadDealDetails = async (dealDetailsid) => {
    if (dealDetailsid === null) {
      return;
    }
    const user = await Auth.currentAuthenticatedUser();
    const userUuid = user.attributes.sub;
    console.log('userUuid: ' + userUuid);

    const myDeal = await fetchDealDetails(dealDetailsid);
    if (myDeal === null) {
      handleOpenDialog(
        'No deal settings found / not autherized with deal id: ' + dealDetailsid
      );
      navigate('/MySharedDealList');
    } else if (myDeal._deleted && myDeal._deleted === true) {
      handleOpenDialog('The deal has been deleted.');
      navigate('/MySharedDealList');
    }

    setDealDetails(myDeal);
    setUserUuid(user.attributes.sub);
    // setInStorePickup(!!myDeal.instore);
    setSelectedKindOfDealOption(myDeal.instore ? 'instore' : 'online');

    console.log('Loaded Deal: ');
    console.log(myDeal);

    if (myDeal.specific_stores === null) {
      myDeal.specific_stores = false;
    }
    if (myDeal.specific_states === null) {
      myDeal.specific_states = false;
    }
    if (myDeal.instore === null) {
      myDeal.instore = false;
    }

    setSelectedKindOfDealOption(myDeal.dealType || 'online');
  };

  const handleDealDelete = async () => {
    console.log('deal to delete: ');
    console.log(dealDetails);
    if (window.confirm('Are you sure to delete?')) {
      let validDealDetails = {
        createNew: false,
        id: dealDetails.id,
        deleteDeal: true,
      };
      const response = await deleteDeal(dealDetails);
      if (!response) {
        alert('Deal Deleted!');
        navigate('/MySharedDealList');
      } else {
        handleOpenDialog(response);
      }
    }
  };

  const fetchDealDetails = async (dealDetailsid) => {
    try {
      const dealDetailsData = await API.graphql({
        query: getDeal,
        variables: {
          id: dealDetailsid,
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
      if (dealDetailsData.data.getDeal) {
        return dealDetailsData.data.getDeal;
      } else {
        console.error('No deal settings found');
        return null;
      }
    } catch (error) {
      console.error('Error fetching deal settings: ', error);
      return null;
    }
  };

  const initEmptyDeal = (isInstore) => {
    // Initialize an empty deal here
    setDealDetails(dealInit(isInstore));
  };

  const validateDealDetails = (dealDetails) => {
    const requiredFieldsOnlineDeal = [
      { field: 'title', message: 'Title is required' },
      { field: 'forum_type', message: 'Category is required' },
      { field: 'dealer_type', message: 'Store is required' },
      {
        field: 'deal_link',
        message: 'Deal link (URL) is required for online deal',
      },
    ];
    const requiredFieldsInstoreDeal = [
      { field: 'title', message: 'Title is required' },
      { field: 'forum_type', message: 'Category is required' },
      { field: 'dealer_type', message: 'Store is required' },
      {
        field: 'deal_link',
        message: 'Deal link (URL) is required for online deal',
      },
    ];
    var requiredFields = dealDetails.instore
      ? requiredFieldsInstoreDeal
      : requiredFieldsOnlineDeal;
    for (let i = 0; i < requiredFields.length; i++) {
      const { field, message } = requiredFields[i];
      if (
        !dealDetails[field] ||
        (Array.isArray(dealDetails[field]) && dealDetails[field].length === 0)
      ) {
        handleOpenDialog(message);
        return false;
      }
    }
    if (dealDetails.specific_states && dealDetails.specific_stores) {
      handleOpenDialog(
        'Error, please refresh the page and try again or contact admin. [0000110]'
      );
      // console.error(dealDetails);
      return false;
    }
    if (dealDetails.instore === false) {
      dealDetails.specific_states = false;
      dealDetails.specific_stores = false;
    }
    if (
      dealDetails.instore &&
      !dealDetails.specific_states &&
      !dealDetails.specific_stores
    ) {
      handleOpenDialog(
        'Error, please refresh the page and try again or contact admin. [0000112]'
      );
      return false;
    }
    if (
      dealDetails.instore &&
      dealDetails.specific_states &&
      dealDetails.available_states.length === 0
    ) {
      handleOpenDialog('At least one available state must be chosen.');
      return false;
    }
    if (
      dealDetails.instore &&
      dealDetails.specific_stores &&
      dealDetails.available_store_addresses.length === 0
    ) {
      handleOpenDialog('At least one address.');
      return false;
    }
    return true;
  };

  const createANewDeal = async () => {
    if (!validateDealDetails(dealDetails)) {
      setButtonLoading(false);
      return;
    }

    const res = await dealCreate(dealDetails);
    if (!res[0]) {
      navigate(`/deal-details/${res[1]}`);
    } else {
      handleOpenDialog(res[0]);
    }

    setButtonLoading(false);
  };

  const updateMyDeal = async () => {
    if (!validateDealDetails(dealDetails)) {
      setButtonLoading(false);
      return;
    }

    const response = await dealUpdate(dealDetails);
    if (!response[0]) {
      navigate(`/deal-details/${response[1]}`);
    } else {
      handleOpenDialog(`[0011010] ${response[0]}`);
    }

    setButtonLoading(false);
  };

  if (isLoading) {
    return (
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 4,
            my: 5,
          }}
        >
          &nbsp;&nbsp;
          <Grid item xs={12}>
            <Typography variant="body1">loading...</Typography>
          </Grid>
        </Box>
        <Box style={{ height: '400px' }} />
      </Container>
    );
  }

  return (
    // <Container sx={{ bgcolor: '#F7FAFC', width: '100%', alignItems: 'center'}} maxWidth={false}>
    <Container sx={{ width: '100%', alignItems: 'center' }} maxWidth={false}>
      <Grid container spacing={2}>
        {/*

        <Grid item xs={12} md={12}>
          <Box>
            {dealDetailsId && <AdminDealToolBox dealId={dealDetailsId} />}
          </Box>
        </Grid>
        */}
        {(isMedium || isLarge) && (
          <DealEditorStepper
            dealDetails={dealDetails}
            inStorePickup={dealDetails.instore}
            isMedium={isMedium}
            isLarge={isLarge}
            isSmall={isSmall}
            setDisabledSubmit={setDisabledSubmit}
            selectedKindOfDealOption={selectedKindOfDealOption}
            completedSteps={completedSteps}
            setCompletedSteps={setCompletedSteps}
          />
        )}
        {isSmall && (
          <>
            <Grid item xs={12} sm={10} md={10} lg={10} sx={{ my: 0 }}>
              {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 0, my: 4}}> */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  my: 4,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="tw-text-string-base"
                >
                  <img
                    src="/email/logo.png"
                    alt=""
                    style={{ width: '50px', height: '50px' }}
                  />
                  {'\u00A0\u00A0'}Share a Deal
                </Typography>
              </Box>
            </Grid>
            <DealEditorStepper
              dealDetails={dealDetails}
              inStorePickup={dealDetails.instore}
              isMedium={isMedium}
              isLarge={isLarge}
              isSmall={isSmall}
              setDisabledSubmit={setDisabledSubmit}
              selectedKindOfDealOption={selectedKindOfDealOption}
              completedSteps={completedSteps}
              setCompletedSteps={setCompletedSteps}
            />
          </>
        )}
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              px: 0,
            }}
          >
            {(isMedium || isLarge) && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  my: 2,
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="tw-text-string-base"
                >
                  <img
                    src="/email/logo.png"
                    alt=""
                    style={{ width: '50px', height: '50px' }}
                  />
                  {'\u00A0\u00A0'}Share a Deal
                </Typography>
              </Box>
            )}
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography variant="h6" className="tw-text-string-base">
                  {' '}
                  Kind of Deal{' '}
                </Typography>
              </Box>
              <FormGroup className="mb-3" controlId="formBasicText">
                <RadioGroup
                  aria-label="options"
                  name="options"
                  value={selectedKindOfDealOption}
                  onChange={(event) => {
                    setSelectedKindOfDealOption(event.target.value);
                    // setInStorePickup(event.target.value === 'instore');
                    setDealDetails({
                      ...dealDetails,
                      instore: event.target.value === 'instore',
                      dealType: event.target.value,
                    });
                  }}
                  row
                >
                  <FormControlLabel
                    value="online"
                    control={<Radio />}
                    label="Online"
                    className="tw-text-string-base"
                  />
                  <FormControlLabel
                    value="instore"
                    control={<Radio />}
                    label="In-store only"
                    className="tw-text-string-base"
                  />
                </RadioGroup>
              </FormGroup>
              {isAdmin && (
                <DealEditorDealLabel
                  dealDetails={dealDetails}
                  setDealDetails={setDealDetails}
                  inStorePickup={dealDetails.instore}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  my: 2,
                }}
              >
                {!dealDetailsId ? (
                  <Typography variant="h6" className="tw-text-string-base">
                    {' '}
                    {dealDetails.instore
                      ? 'Share an In-store pickup Deal'
                      : 'Share a Deal'}{' '}
                  </Typography>
                ) : (
                  <Typography variant="h6" className="tw-text-string-base">
                    Deal Editor {dealDetails.instore ? '(In-store Deal)' : ''}{' '}
                  </Typography>
                )}
              </Box>
              <DealEditorTextField
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
                inStorePickup={dealDetails.instore}
              />
              <DealEditorStoreCategory
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
                inStorePickup={dealDetails.instore}
              />
              <DealEditorCheckBoxes
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
              />
              <DealEditorSwitches
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
              />
              <DealEditorImgUploader
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
                inStorePickup={dealDetails.instore}
                handleOpenDialog={handleOpenDialog}
              />
              {dealDetails.instore && (
                <DealEditorAddressSelector
                  dealDetails={dealDetails}
                  setDealDetails={setDealDetails}
                  setDialogInfo={setDialogInfo}
                />
              )}
              <DealEditorSocalManager
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
                inStorePickup={dealDetails.instore}
              />
              <DealEditorSocalTemplate
                dealDetails={dealDetails}
                setDealDetails={setDealDetails}
                inStorePickup={dealDetails.instore}
              />
              &nbsp;&nbsp;
            </FormControl>
          </Box>
          {/* </Grid>

                    <Grid item xs={12} lg={12} sx={{ mb: 5}}> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {dealDetailsId && (
                  <>
                    <LoadingButton
                      variant="contained"
                      onClick={async () => {
                        setButtonLoading(true);
                        updateMyDeal();
                      }}
                      style={{ flex: 3, width: '100%' }}
                      loading={buttonLoading}
                      disabled={disabledSubmit}
                    >
                      Save
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigate(`/MySharedDealList`);
                      }}
                      style={{ flex: 1, marginLeft: '10px' }}
                      sx={{ width: '100%', height: '40px' }}
                    >
                      Cancel
                    </Button>
                    <IconButton
                      variant="outlined"
                      onClick={handleDealDelete}
                      style={{ flex: 1, marginLeft: '10px' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
                {!dealDetailsId && (
                  <>
                    <LoadingButton
                      variant="contained"
                      onClick={async () => {
                        setButtonLoading(true);
                        createANewDeal();
                      }}
                      style={{ flex: 2 }}
                      sx={{ width: '100%', height: '40px' }}
                      loading={buttonLoading}
                      disabled={disabledSubmit}
                    >
                      Submit
                    </LoadingButton>
                    {/* <Link to='/' style={{ flex: 1, marginLeft: '10px' }}>
                                        <Button variant="outlined" sx={{ width: '100%', height: '40px' } }>
                                            Cancel
                                        </Button>
                                    </Link> */}
                  </>
                )}
              </div>
              &nbsp;&nbsp;
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <DealEditorDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
    </Container>
  );
}

export default DealEditorIndex;
