import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { listDealsByTimeApi } from '../../api/deal';
import { cleanAndLowercase } from '../utils/cleanStringForSearch';

function promo_alphabetically(ascending) {
  return function (a, b) {
    // equal items sort equally
    if (a.promotional_code === b.promotional_code) {
      return 0;
    }

    // nulls sort after anything else
    if (a.promotional_code === null) {
      return 1;
    }
    if (b.promotional_code === null) {
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return a.promotional_code < b.promotional_code ? -1 : 1;
    }

    // if descending, highest sorts first
    return a.promotional_code < b.promotional_code ? 1 : -1;
  };
}
/**
 * Handle sorting for price drop
 *
 * @param {bool} ascending - Whether the sorting is ascending or descending
 * @returns {bool} - if we're ascending, lower price_drop return -1. if we are descending, lower price_drop return 1.
 *
 * This method first check whether deal A and deal B have valid prev_price.
 * A valid prev_price means 1. have prev_price 2. prev_price > price.
 * If both A and B don't have valid prev_price, return 0;
 * If one of them have valid prev_price, it has larger price_drop
 * If both of the mhave valid prev_price, we compare which price_drop is larger
 *
 */
function price_drop_alphabetically(ascending) {
  return function (a, b) {
    // nulls sort after anything else
    if (a.prev_price === null) {
      if (b.prev_price === null) {
        return 0;
      } else if (b.prev_price < b.price) {
        return 0;
      }
      return 1;
    }
    if (b.prev_price === null) {
      if (a.prev_price < a.price) {
        return 0;
      }
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    let a_price_drop = (a.prev_price - a.price) / a.prev_price;
    let b_price_drop = (b.prev_price - b.price) / b.prev_price;
    if (ascending) {
      return a_price_drop < b_price_drop ? -1 : 1;
    }

    // if descending, highest sorts first
    return a_price_drop < b_price_drop ? 1 : -1;
  };
}

function searchParametersToFilter(searchParameters) {
  const parsedFilter = {
    free_shipping: searchParameters.get('free_shipping'),
    promo_code_included: searchParameters.get('promo_code_included'),
    coupon_included: searchParameters.get('coupon_included'),
    price_min: searchParameters.get('price_min'),
    price_max: searchParameters.get('price_max'),
    dealer_types: searchParameters.getAll('dealer_types'),
    forum_types: searchParameters.getAll('forum_types'),
    sub_categories: searchParameters.getAll('sub_categories'),
    sort_by: searchParameters.get('sort_by'),
    q: searchParameters.getAll('q'),
  };

  Object.entries(parsedFilter).forEach(([key, value]) => {
    if (key === 'free_shipping' || key === 'instore') {
      if (value === null || value !== 'true') {
        parsedFilter[key] = false;
      } else {
        parsedFilter[key] = true;
      }
    } else if (key === 'promo_code_included') {
      if (value === null || value !== 'true') {
        parsedFilter[key] = false;
      } else {
        parsedFilter[key] = true;
      }
    } else if (key === 'coupon_included') {
      if (value === null || value !== 'true') {
        parsedFilter[key] = false;
      } else {
        parsedFilter[key] = true;
      }
    } else if (key === 'price_min' || key === 'price_max') {
      if (value === null) {
        parsedFilter[key] = 0;
      } else {
        parsedFilter[key] = parseInt(value);
      }
    } else if (key === 'dealer_types' || key === 'forum_types' || key === 'sub_categories') {
      if (
        !Array.isArray(value) ||
        value.every((entry) => typeof entry !== 'string')
      ) {
        parsedFilter[key] = [];
      } else {
        parsedFilter[key] = value;
      }
    } else if (key === 'sort_by') {
      if (
        value === null ||
        ![
          'Most Recent',
          'Top Deals',
          'Percentage Off',
          'Trending Deals',
          'Promo code included',
        ].includes(value)
      ) {
        parsedFilter[key] = 'Most Recent';
      } else {
        parsedFilter[key] = value;
      }
    } else if (key === 'q') {
      parsedFilter[key] = value;
    }
  });
  // console.log('parsed filter', parsedFilter);
  return parsedFilter;
}

function filterToSearchParameters(filter) {
  const filterTemp = structuredClone(filter);
  Object.entries(filterTemp).forEach(([key, value]) => {
    if (
      value === false ||
      value === 0 ||
      (Array.isArray(value) && value.length === 0)
    ) {
      delete filterTemp[key];
    }
  });
  return filterTemp;
}
/*Update Big Deal dealers here*/
function createFilterQuery(filter) {
  const {
    free_shipping,
    promo_code_included,
    coupon_included,
    price_min,
    price_max,
    dealer_types,
    forum_types,
    sub_categories,
    q,
  } = filter;

  let filterQuery = {
    and: [
      {
        free_shipping: { ne: free_shipping === true ? false : null },
      },

      {
        instore: { eq: false },
      },

      {
        has_promotional_code:
          promo_code_included === true ? { eq: true } : { ne: null },
      },

      {
        has_coupon: coupon_included === true ? { eq: true } : { ne: null },
      },
      {
        "or": [
            {
                "dealer_type": {
                    "contains": "Target"
                }
            },
        ]
    },
    ],
  };

  if (!(price_min === price_max && price_min === 0)) {
    filterQuery.and.push({
      price: { ge: price_min },
    });
    filterQuery.and.push({
      price: { le: price_max },
    });
  }

  if (forum_types.length > 0) {
    if (!filterQuery.or) {
      filterQuery.or = [];
    }
    filterQuery.or = filterQuery.or.concat(
      forum_types.map((forum_type) => ({
        forum_type: { contains: forum_type },
      }))
    );
  }

  if (dealer_types.length > 0) {
    if (!filterQuery.and) {
      filterQuery.and = [];
    }
    let dealer_filters = [];
    dealer_filters = dealer_filters.concat(
      dealer_types.map((dealer_type) => ({
        dealer_type: { contains: dealer_type },
      }))
    );
    filterQuery.and = filterQuery.and.concat({ or: dealer_filters });
  }

  if (sub_categories.length > 0) {
    if (!filterQuery.or) {
      filterQuery.or = [];
    }
    filterQuery.or = filterQuery.or.concat(
      sub_categories.map((sub) => ({
        sub_category: { contains: sub },
      }))
    );
  }
  // console.log('createQuery');

  if (q.length > 0) {
    filterQuery.and = filterQuery.and.concat(
      q.map((each_q) => ({
        or: [
          { title_lowercase: { contains: cleanAndLowercase(each_q) } },
          { description_lowercase: { contains: cleanAndLowercase(each_q) } },
          { forum_type_lowercase: { contains: cleanAndLowercase(each_q) } },
          { dealer_type_lowercase: { contains: cleanAndLowercase(each_q) } },
        ],
      }))
    );
  }
  // console.log('current query', filterQuery);
  return filterQuery;
}

function useListBigDayDeals() {
  const [deals, setDeals] = useState([]);
  const [bigDayTargetDeals, setTargetDeals] = useState([]);
  const [bigDayAmazonDeals, setAmazonDeals] = useState([]);
  const [bigDayWalmartDeals, setWalmartDeals] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [filter, setFilter] = useState(
    searchParametersToFilter(searchParameters)
  );
  const [pageSize, setPageSize] = useState(300);
  const DST = (() => {
    const cur_date = new Date();
    let jan = new Date(cur_date.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(cur_date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) != cur_date.getTimezoneOffset();
  })();
  const initialMount = useRef(true);

  const { data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['dealsByTimeBigData', filter],
    queryFn: ({ nextToken = null }) => getListDeals(nextToken),
    getNextPageParam: (lastPage) => lastPage?.data?.dealsByDate?.nextToken,
    enabled: true,
    staleTime: 300000,
  });

  /*Update Big Deal dealers here*/
  const getListDeals = async (nextToken) => {
    const filterQuery = createFilterQuery(filter);
    // console.log('the filter used', filterQuery);
    let response = await listDealsByTimeApi(
      filterQuery,
      filterQuery?.q?.length > 0 ? 1000 : pageSize,
      nextToken
    );
    let nonExpiredLightningDeals = [];
    //let backupAmazonDeals = [];

    response.data.dealsByDate.items.map((deal, indx) => {
      // console.log(deal);
      if ( deal.isLightningDeal === 'true' || deal.dealType === 'lightning') {
        // console.log(deal.id, ' is Lightning')
        if (!['deleted', 'expired'].includes(hoursDifferenceVal(deal.updatedAt))) {
          if(deal.dealer_type !== 'Walmart' && deal.dealer_type !== 'Amazon'){
            nonExpiredLightningDeals.push(deal);
          }
          else if(deal.dealer_type == 'Amazon'){
            // backupAmazonDeals.push(deal);
          }
        }
        else{
          // console.log(deal.title, 'Is filtered!');
        }
      }
      else{
        if(deal.dealer_type !== 'Walmart' && deal.dealer_type !== 'Amazon'){
          nonExpiredLightningDeals.push(deal);
        }
        else if(deal.dealer_type == 'Amazon'){
          // backupAmazonDeals.push(deal);
        }
      }
    })
    // console.log('filtered results:', nonExpiredLightningDeals);
    response.data.dealsByDate.items = nonExpiredLightningDeals;

    return response.data.dealsByDate;
  };

  /*To filter out expired Lightning Deals.*/
  const hoursDifferenceVal = (date) => {
    const past = new Date(date); // posted time according to user current TimeFrame
    const now = new Date(); // Current EST TIME

    let threeAM;

    if (DST) {
      // + 4 UTC
      threeAM = new Date().setUTCHours(7, 0, 0, 0); // !!! actual is 7
      let threeAMDate = new Date(threeAM);
      if (threeAMDate.getDate() !== now.getDate()) {
        threeAM -= 24 * 60 * 60 * 1000;
      }
      threeAMDate = new Date(threeAM);
    } else {
      // + 5 UTC
      threeAM = new Date().setUTCHours(8, 0, 0, 0); // !!! actual is 8
      let threeAMDate = new Date(threeAM);
      if (threeAMDate.getDate() !== now.getDate()) {
        threeAM -= 24 * 60 * 60 * 1000;
      }
      threeAMDate = new Date(threeAM);
    }

    const secondsA = Math.floor((past - threeAM) / 1000);
    const minutesA = Math.floor(secondsA / 60);
    const hoursA = Math.floor(minutesA / 60);

    if (hoursA < 0) {
      return 'deleted';
    } else {
      const seconds = Math.floor((now - past) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      // console.log('hours: ',hours);
      if (hours >= 0 && hours < 3) {
        /// ! actual is 3
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      } else {
        return 'expired';
      }
    }
  };

  const sortDeals = (sort) => {
    // remove dup and sort
    setDeals((prevState) => {
      let prevStateClone = structuredClone(prevState);

      // Remove duplicates
      prevStateClone = prevStateClone.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.id === current.id);
        if (x <= -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Sort
      if (sort === 'Alphabetically') {
        prevStateClone.sort((a, b) =>
          String(a.title).localeCompare(String(b.title))
        );
      }
      if (sort === 'Promo code included') {
        prevStateClone.sort(promo_alphabetically(false));
      }
      if (sort === 'Most Recent') {
        prevStateClone.sort((a, b) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
      }
      if (sort === 'Top Deals') {
        prevStateClone.sort(
          (a, b) => b.vote - b.down_vote - (a.vote - a.down_vote)
        );
      }
      if (sort === 'Percentage Off') {
        // prevStateClone.sort((a, b) => b.price_drop - a.price_drop);
        prevStateClone.sort(price_drop_alphabetically(false));
        // console.log(prevStateClone);
      }
      // if (sort === 'Trending Deals') {
      //   prevStateClone.sort((a, b) => b.trending_tag - a.trending_tag);
      // }

      return prevStateClone;
    });
  };

  const selectedFiltersCount = (filter) => {
    let count = 0;

    if (filter.promo_code_included === true) {
      count++;
    }
    if (filter.coupon_included === true) {
      count++;
    }
    if (filter.free_shipping === true) {
      count++;
    }
    if (filter.price_min > 0) {
      count++;
    }
    if (filter.price_max > 0) {
      count++;
    }

    count +=
      filter.dealer_types?.length +
      filter.forum_types?.length +
      filter.sub_categories?.length;

    return count;
  };

  useEffect(() => {
    if (!initialMount?.current) {
      setSearchParameters(filterToSearchParameters(filter));
    }
    setSelectedCount(selectedFiltersCount(filter));
  }, [filter]);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    }
    setFilter(searchParametersToFilter(searchParameters));
  }, [searchParameters]);

  return {
    data,
    fetchNextPage,
    isFetching,
    hasNextPage,
    deals,
    filter,
    loading,
    selectedCount,
    setFilter,
    setLoading,
    sortDeals,
    selectedFiltersCount,
    getListDeals,
    setPageSize,
  };
}

export default useListBigDayDeals;