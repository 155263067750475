import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid } from '@mui/x-data-grid';
import { API, Auth } from 'aws-amplify';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  createConfidentialsAdmin,
  deleteConfidentialsAdmin,
  updateConfidentialsAdmin,
} from '../../graphql/mutations';
import { listConfidentialsAdmins } from '../../graphql/queries';

export default function AdminConfidentialsPage(props) {
  const navigate = useNavigate();
  const REACT_APP_PROFILE_BUCKET_NAME =
    process.env.REACT_APP_PROFILE_BUCKET_NAME;
  const [dialogInfo, setDialogInfo] = useState('');

  const [cognitoUser, setCognitoUser] = useState(null);

  const [bodyFontSize, setBodyFontSize] = useState('1.2em');
  const [headerFontSize, setHeaderFontSize] = useState('1.8em');
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedConfidential, setSelectedConfidential] = useState({
    id: '',
    ConfidentialKey: '',
    ConfidentialValue: '',
  });

  const handleEditDialogOpen = (confidential) => {
    setSelectedConfidential(confidential);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedConfidential({
      id: '',
      ConfidentialKey: '',
      ConfidentialValue: '',
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedConfidential((prev) => ({ ...prev, [name]: value }));
  };

  const decodeTokenExp = (token) => {
    try {
      const decoded = jwtDecode(token);
      const exp = decoded.exp;
      const now = Math.floor(Date.now() / 1000);
      const remainingSeconds = exp - now;
      const remainingHours = Math.floor(Math.abs(remainingSeconds) / 3600);
      if (remainingSeconds >= 0) {
        return `${remainingHours} h remaining`;
      } else {
        return `Expired (${remainingHours} h ago)`;
      }
    } catch (error) {
      console.error('Error decoding token:', error);
      return 'Invalid Token';
    }
  };

  const handleEditSubmit = async () => {
    try {
      const { id, ConfidentialKey, ConfidentialValue, _version } =
        selectedConfidential;
      await API.graphql({
        query: updateConfidentialsAdmin,
        variables: {
          input: { id, ConfidentialKey, ConfidentialValue, _version: _version },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      handleEditDialogClose();
    } catch (error) {
      console.error('Error updating confidential:', error);
    }
    fetchConfidentials();
  };

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newConfidential, setNewConfidential] = useState({
    id: uuid(),
    ConfidentialKey: '',
    ConfidentialValue: '',
  });

  const handleCreateDialogOpen = () => {
    setCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
    setNewConfidential({
      id: uuid(),
      ConfidentialKey: '',
      ConfidentialValue: '',
    });
  };

  const handleDelete = async (confidential) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this confidential?'
    );
    if (!confirmed) return;

    try {
      await API.graphql({
        query: deleteConfidentialsAdmin,
        variables: {
          input: {
            id: confidential.id,
            _version: confidential._version,
          },
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      fetchConfidentials();
    } catch (error) {
      console.error('Error deleting confidential:', error);
    }
  };

  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setNewConfidential((prev) => ({ ...prev, [name]: value }));
  };

  const handleCreateSubmit = async () => {
    try {
      await API.graphql({
        query: createConfidentialsAdmin,
        variables: { input: newConfidential },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      handleCreateDialogClose();
    } catch (error) {
      console.error('Error creating confidential:', error);
    }
    fetchConfidentials();
  };

  useEffect(() => {
    if (isSmall) {
      setHeaderFontSize('1.6em');
      setBodyFontSize('1em');
    } else if (isMedium) {
      setHeaderFontSize('1.6em');
      setBodyFontSize('1em');
    } else if (isLarge) {
      setHeaderFontSize('2em');
      setBodyFontSize('1.2em');
    }
  }, [isSmall, isMedium, isLarge]);

  const handleOpenDialog = (info) => {
    setDialogInfo(info);
  };

  const handleCloseDialog = () => {
    setDialogInfo('');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        if (!groups || !groups.includes('admin')) {
          navigate('/'); // Redirect to home page if user is not an admin
        } else {
          setCognitoUser(user); // Set the authenticated user to the coguser state
        }
      })
      .catch((err) => {
        console.log(err);
        navigate('/'); // Redirect to home page if user is not logged in
      });
  }, []);

  const fetchConfidentials = async () => {
    try {
      const result = await API.graphql({
        query: listConfidentialsAdmins,
        variables: {
          filter: { _deleted: { ne: true } },
          limit: 100,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS', // Ensure the query uses the correct auth mode
      });
      console.log(result);
      const items = result.data.listConfidentialsAdmins.items;
      setRows(items); // Set the fetched items to rows state
    } catch (error) {
      console.error('Error fetching confidentials:', error);
    }
  };

  useEffect(() => {
    fetchConfidentials();
  }, []);

  const columns = [
    {
      field: 'ConfidentialKey',
      headerName: 'Confidential Key',
      width: 150,
    },
    {
      field: 'ConfidentialValue',
      headerName: 'Confidential Value',
      width: 170,
    },

    {
      field: 'token_exp',
      headerName: 'Token Expiration',
      width: 150,
      valueGetter: (params) => {
        const { ConfidentialKey, ConfidentialValue } = params.row;
        if (ConfidentialValue.startsWith('eyJhbGciOiJSUzI1Ni')) {
          return decodeTokenExp(ConfidentialValue);
        }
        return 'N/A';
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At (UTC)',
      width: 190,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 65,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          onClick={() => handleEditDialogOpen(params.row)}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      sortable: false,
      width: 65,
      renderCell: (params) => (
        <IconButton
          variant="contained"
          onClick={() => handleDelete(params.row)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const [rows, setRows] = useState([]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
              margin: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: headerFontSize }}
              className="tw-text-string-base"
            >
              Confidentials
            </Typography>
            <Button variant="outlined" onClick={handleCreateDialogOpen}>
              Create New
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              // pageSizeOptions={[5]}
              // checkboxSelection
              // disableRowSelectionOnClick
            />
          </Box>
        </Grid>

        {/* <Grid item xs={12} ><Divider variant="middle" sx={{borderWidth: 0.8, borderColor: 'black'}} /></Grid> */}
      </Grid>
      &nbsp;&nbsp;
      <Dialog
        open={dialogInfo}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={createDialogOpen}
        onClose={handleCreateDialogClose}
        aria-labelledby="create-dialog-title"
        aria-describedby="create-dialog-description"
      >
        <DialogTitle id="create-dialog-title">
          Create New Confidential
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="ConfidentialKey"
            label="Confidential Key"
            type="text"
            fullWidth
            value={newConfidential.ConfidentialKey}
            onChange={handleCreateChange}
          />
          <TextField
            margin="dense"
            name="ConfidentialValue"
            label="Confidential Value"
            type="text"
            fullWidth
            value={newConfidential.ConfidentialValue}
            onChange={handleCreateChange}
            multiline
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose}>Cancel</Button>
          <Button onClick={handleCreateSubmit} autoFocus>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">Edit Confidential</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="ConfidentialKey"
            label="Confidential Key"
            type="text"
            fullWidth
            value={selectedConfidential.ConfidentialKey}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="ConfidentialValue"
            label="Confidential Value"
            type="text"
            fullWidth
            value={selectedConfidential.ConfidentialValue}
            onChange={handleEditChange}
            multiline
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button onClick={handleEditSubmit} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
