import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppContext_ } from '../../AppContextProvider';
import { ReactComponent as CouponIcon } from '../../icons/coupon_icon.svg';
import { ReactComponent as TruckIcon } from '../../icons/truck.svg';

import {
  Button,
  Checkbox,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import {
  CheckCircle,
  KeyboardArrowRightRounded,
  StorefrontOutlined,
  ThumbDownOutlined,
  ThumbDownTwoTone,
  ThumbUpOutlined,
  ThumbUpTwoTone
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { BiSolidCoupon } from 'react-icons/bi';
import { FaCircleCheck } from 'react-icons/fa6';
import { LuMousePointerClick } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as QuestionIcon } from '../../icons/question_icon.svg';
import useDealDetailsApi from '../apiHooks/useDealDetailsApi';
import useProfileImage from '../apiHooks/useProfileImage';
import { storeIcon } from '../constants/StoreConstants';
import Carousel from '../utils/Carousel';
import CustomDialog from '../utils/CustomDialog';
import CustomExpiredDialog from '../utils/CustomExpiredDialog';
import CustomReportDialog from '../utils/CustomReportDialog';
import BottomAction from './BottomAction';
import CustomCouponDialog from './CustomCouponDialog';
import CustomPromoDialog from './CustomPromoDialog';
import Share from './Share';
import TopAction from './TopAction';

function Deal() {
  const navigate = useNavigate();
  const {
    deal,
    upvoted,
    downvoted,
    bookmark,
    expired,
    report,
    deal_loading,
    postVote,
    postBookmark,
    postExpired,
    postReport,
    likeNumberOffset,
    dislikeNumberOffset,
  } = useDealDetailsApi();
  const { user } = useAppContext_();
  const { profileImage } = useProfileImage(deal?.poster_id);
  const [image, setImage] = useState(0);
  const [share, setShare] = useState(false);
  const [carousel, setCarousel] = useState(false);
  const [promoClicked, setPromoClicked] = useState(false);

  // console.log('expired', expired)

  const handleImage = (index) => setImage(index);
  const handleVote = async (event, value) => await postVote(value);
  const handleAddBookmark = async () => await postBookmark();
  const [expiredClick, setExpiredClick] = useState(expired !== null);
  const [reportClick, setReportClick] = useState(report !== null);
  const [openExpiredDialog, setExpiredOpenDialog] = useState(false);
  const [openReportDialog, setReportOpenDialog] = useState(false);
  const [openCouponDialog, setCouponOpenDialog] = useState(false);
  const [openPromoDialog, setPromoOpenDialog] = useState(false);

  const handleOpenCouponDialog = async (alert) => {
      await setCouponOpenDialog(!openCouponDialog);
  };

  const handleOpenPromoDialog = async (alert) => {
    await setPromoOpenDialog(!openPromoDialog);
};

  const handleExpired = async () => {
    // console.log(expired, expiredClick);
    if (!user) {
      alert('Please log in to mark expired. Thank you！');
    }
    else{
      if(expiredClick == false && !expired){
        setExpiredOpenDialog(!openExpiredDialog);
      }
      await setExpiredClick(!expiredClick);
      await postExpired();
    }
  };
  const handleOpenExpiredDialog = async (alert) => {
      await setExpiredOpenDialog(!openExpiredDialog);
  };

  const handleReport = async () => {
    if (!user) {
      alert('Please login in to report. Thank you!')
    }
    else{
      if(reportClick == false && !report){
        setReportOpenDialog(!openReportDialog);
      }
      await setReportClick(!reportClick);
      await postReport();
    }

  }
  const handleOpenReportDialog = async (alert) => {
    await setReportOpenDialog(!openReportDialog);
  };

  const handleShare = async () => setShare(!share);
  const handleCarousel = async () => setCarousel(!carousel);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const GoogleMapAddressURL = 'https://www.google.com/maps/place/?q=';

  useEffect(() => {
    // window.history.scrollRestoration = 'manual'
    // window.scrollTo(0, 0);
    //console.log('Is Expired:', expired);
    // console.log('Expire clicked:', expiredClick);
  }, []);

  useEffect(() => {
    // window.history.scrollRestoration = 'manual'
    // window.scrollTo(0, 0);
    setExpiredClick(expired !== null);
    setReportClick(report !== null);
    //console.log('Loaded Is Expired:', expired);
    //console.log('Loaded Expire clicked:', expired !== null);
    //console.log('Loaded Report:', report);
    //console.log('Loaded Report clicked:', report !== null);
  }, [deal_loading]);

  if (deal_loading) {
    return (
      <div className="tw-h-[726px] tw-animate-pulse tw-bg-gray-300 tw-rounded-xl"></div>
    );
  }

  const profileNameClick = (event) => {
    // navigate('/componentB',{state:{id:1,name:'sabaoon'}})
    navigate(`/testPublicProfile/${deal.poster_id}/${deal.poster_name}`);
  };

  const handleButtonClick = async (event) => {
    let url = `${GoogleMapAddressURL}${deal.dealer_type} ${event.target.innerText}`;
    window.open(url, '_blank', 'noopener,noreferrer');
    setAnchorEl(null);
  };

  const handleMostButtonClick = async (event) => {
    // alert(event.target.innerText);
    setAnchorEl(null);
    alert('Available in most locations');
    // navigate('/categoryForum', { state: { name: event.target.innerText } });

    // window.location.reload(true);
    //navigate("/");
  };

  const styles = {
    textTransform: 'none',
    justifyContent: 'flex-start',
  };

  const toggleStoreLink = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = async (event, value) => {
    setAnchorEl(null);
    //navigate("/categoryForum", { state: { name: res } });
  };

  function handleInStoreAddressMessage(item) {
    if (item.specific_states) {
      return `Available in most ${item.dealer_type} store locations`;
    }
    if (item.available_store_addresses) {
      return `Available in certain ${deal.dealer_type} stores.`;
    } else {
      return 'We are waiting the deal sharer to provide the deal address';
    }
  }

  const daysDifference = (date) => {
    const past = new Date(date);
    const now = new Date();

    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (days < 7) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    } else {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    }
  };

  function changeDescription(item) {
    if (item.description) {
      if (item.description.length >= 180) {
        return item.description;
      } else {
        return item.description;
      }
    } else {
      if (item.instore && item.instore == true) {
        return 'Visit the store for more info';
      } else {
        return 'No details have been provided by the poster. Please check the link for more info.';
      }
    }
  }

  function pricePeriod(price) {
    if (price) {
      if (price % 1 == 0) {
        return price;
      } else {
        return price.toFixed(2);
      }
    } else {
      return 0;
    }
  }

  const firstClickPromo = async () => {
    setPromoClicked(true);
    navigator.clipboard.writeText(deal?.promotional_code);
  };

  const clickPromo = async () => {
    navigator.clipboard.writeText(deal?.promotional_code);
  };
  return (
    <div>
      <TopAction
        expired={expired}
        handleExpired={handleExpired}
        report={report}
        handleReport={handleReport}
      />
      <div className="tw-mt-0 tw-pt-0 sm:tw-mt-0 tw-flex tw-flex-col md:tw-flex-row tw-gap-5 lg:tw-gap-8 tw-p-2 sm:tw-p-8 md:tw-p-4 lg:tw-p-7 sm:tw-bg-surface-raised sm:tw-border sm:tw-border-surface-border sm:tw-rounded-xl">
        <div className="tw-w-full md:tw-w-[40%] lg:tw-w-1/2 xl:tw-w-[60%] md:tw-max-h-[680px] tw-flex tw-flex-col xl:tw-flex-row-reverse tw-gap-2 sm:tw-gap-4">
          <img
            className="tw-flex-1 tw-shrink tw-border tw-min-w-0 tw-border-surface-border tw-rounded-lg tw-bg-surface-base tw-p-2 sm:tw-p-8 tw-aspect-square tw-object-contain"
            src={deal.uploaded_img_links[image]}
            onClick={handleCarousel}
          />
          {deal.uploaded_img_links.length > 1 && (
            <div className="tw-flex tw-shrink-0 tw-flex-row xl:tw-flex-col tw-gap-4 tw-overflow-auto">
              {deal.uploaded_img_links.map((imgLink, index) => (
                <img
                  key={index}
                  src={imgLink}
                  alt={`Image ${index}`}
                  className={`tw-w-16 sm:tw-w-28 tw-border tw-rounded-lg tw-bg-surface-base tw-p-2 tw-object-contain tw-aspect-square ${
                    image === index
                      ? 'tw-border-surface-elevate'
                      : 'tw-border-surface-border'
                  }`}
                  onClick={() => handleImage(index)}
                />
              ))}
            </div>
          )}
        </div>
        <div className="tw-w-full md:tw-w-[60%] lg:tw-w-1/2 xl:tw-w-[40%] tw-flex tw-flex-col tw-gap-y-2 sm:tw-gap-y-4">
          <div className="tw-text-sm lg:tw-text-base tw-font-medium tw-text-string-neutral tw-relative">
            <div
              className="tw-animate-shine tw-group hover:tw-cursor-pointer tw-flex tw-gap-x-2 tw-items-center"
              onClick={profileNameClick}
            >
              <img src={profileImage} className='tw-h-7 tw-w-7 tw-rounded-full'/>
              {deal.poster_name} • {daysDifference(deal.updatedAt)}
            </div>
            <div className="tw-absolute tw-right-0 -tw-bottom-[7px]">
              <IconButton onClick={handleShare}>
                <img className="tw-h-6 tw-w-6" src="/shareGradientBlue.svg" />
              </IconButton>
            </div>
          </div>
          <div className="tw-font-semibold tw-text-xl lg:tw-text-2xl tw-text-string-base">
            {deal.title}
          </div>
          <div className="tw-flex sm:tw-hidden tw-text-string-neutral tw-gap-x-4 tw-text-base">
            <span className="tw-flex tw-items-center tw-gap-x-1">
              <ThumbUpOutlined fontSize="small" />
              <span>{deal.vote + likeNumberOffset + ' likes'}</span>
            </span>
            <span className="tw-flex tw-items-center tw-gap-x-1">
              <ThumbDownOutlined fontSize="small" />
              <span>{deal.down_vote + dislikeNumberOffset + ' dislikes'}</span>
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-gap-x-2">
            <span className="tw-font-semibold tw-text-2xl sm:tw-text-5xl tw-text-string-secondary">
              ${pricePeriod(deal.price)}
            </span>
            {deal.prev_price && (
              <span className="tw-text-2xl tw-line-through tw-text-string-neutral">
                ${pricePeriod(deal.prev_price)}
              </span>
            )}
          </div>
          <div className="tw-flex tw-gap-x-6 tw-gap-y-2 tw-flex-wrap tw-font-semibold tw-text-string-base">
            <div className="tw-flex tw-gap-x-2 tw-items-center tw-justify-center">
              {deal.dealer_type in storeIcon && (
                <img
                  src={storeIcon[deal.dealer_type]}
                  alt={deal.dealer_type}
                  className="tw-object-contain tw-rounded-full tw-w-8 tw-h-8"
                />
              )}
              <span className="tw-whitespace-nowrap">{deal.dealer_type}</span>
            </div>
            <div className="tw-flex tw-gap-x-2 tw-items-center">
              <div className="tw-w-full tw-flex tw-flex-1 tw-items-center tw-gap-x-2 tw-text-string-base">
                <div className="tw-relative">
                  <TruckIcon className="tw-h-[32px] tw-w-[32px]" />
                  <div className="tw-absolute -tw-bottom-[1px] -tw-right-1">
                    <CheckCircle className="!tw-rounded-full !tw-bg-white !tw-fill-green-700 !tw-w-[15px] !tw-h-[15px]" />
                  </div>
                </div>
                <span className="tw-whitespace-nowrap">
                  {deal.free_shipping == true
                    ? 'Free Shipping '
                    : 'Paid Delivery '}{' '}
                  {deal.free_pickup == true ? '& Free Pickup' : ''}
                </span>
              </div>
              {/* <LocalShippingOutlined fontSize="large" />
              <span className="tw-whitespace-nowrap">
                {deal.free_shipping == true
                  ? 'Free Shipping '
                  : 'Paid Delivery '}{' '}
                {deal.free_pickup == true ? '& Free Pickup' : ''} */}
              {/* </span> */}
            </div>
            {deal.instore && deal.instore == true && (
              <div
                className={`tw-underline tw-truncate tw-underline-offset-4 tw-flex tw-items-center ${
                  deal.specific_states ? 'tw-gap-2' : 'tw-gap-0'
                }`}
              >
                <StorefrontOutlined fontSize="large" />
                {deal.specific_states ? (
                  handleInStoreAddressMessage(deal)
                ) : (
                  <Button
                    color="inherit"
                    className="!tw-text-base !tw-underline !tw-truncate !tw-underline-offset-4 !tw-flex !tw-gap-2"
                    onClick={toggleStoreLink}
                    sx={styles}
                  >
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          // maxHeight: ITEM_HEIGHT * 4.5,
                          // width: '30ch',
                        },
                      }}
                    >
                      {deal.available_store_addresses.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          onClick={handleButtonClick}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                    {handleInStoreAddressMessage(deal)}
                    <ExpandMoreIcon />
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="tw-text-base tw-text-string-base">
            {changeDescription(deal)}
          </div>
          <div className="tw-hidden sm:tw-flex tw-text-string-neutral tw-gap-x-4 tw-text-base">
            <span className="tw-flex tw-items-center tw-gap-x-1">
              <ThumbUpOutlined fontSize="small" />
              <span>{deal.vote + likeNumberOffset + ' likes'}</span>
            </span>
            <span className="tw-flex tw-items-center tw-gap-x-1">
              <ThumbDownOutlined fontSize="small" />
              <span>{deal.down_vote + dislikeNumberOffset + ' dislikes'}</span>
            </span>
          </div>
          {!!deal.promotional_code && (
            <div className="tw-flex tw-gap-4 tw-flex-wrap sm:tw-flex-nowrap">
              <div className="tw-flex tw-gap-x-1 tw-items-center">
                <BiSolidCoupon size={25} className="!tw-text-string-primary" />
                <span className="tw-text-string-base tw-whitespace-nowrap">
                  Promo Code
                </span>
                <div onClick={handleOpenPromoDialog}>
                  <IconButton>
                      <QuestionIcon className='tw-w-question-icon tw-h-question-icon tw-items-start'/>
                  </IconButton>
                </div>
              </div>
              <TextField
                fullWidth
                className="!tw-block md:!tw-hidden lg:!tw-block"
                placeholder={deal.promotional_code}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
                sx={{ width: 'fit' }}
                inputProps={{
                  onClick: () => {
                    firstClickPromo();
                  },
                }}
              />
              <Button
                fullWidth
                variant="outlined"
                className="tw-whitespace-nowrap"
                onClick={firstClickPromo}
                startIcon={
                  promoClicked ? (
                    <FaCircleCheck style={{ color: 'rgba(46, 125, 50, 1)' }} />
                  ) : (
                    <LuMousePointerClick className="!tw-text-string-primary" />
                  )
                }
              >
                {promoClicked ? 'Copied' : 'Click to copy'}
              </Button>
            </div>
          )}
          {!!deal.coupon && (
            <div className="tw-flex tw-gap-4 tw-flex-wrap sm:tw-flex-nowrap">
              <div className="tw-flex tw-gap-x-1 tw-items-center">
                <div className = 'tw-flex tw-justify-start tw-items-center'>
                  <CouponIcon size={25} className="!tw-text-string-primary" />
                </div>
                <>
                {deal.coupon.charAt(0) == '$' ?(
                  <span className="tw-text-string-base tw-whitespace-nowrap tw-flex tw-flex-row tw-items-center">
                    {deal.coupon} Coupon
                    <div onClick={handleOpenCouponDialog}>
                        <IconButton>
                            <QuestionIcon className='tw-w-question-icon tw-h-question-icon tw-items-start'/>
                        </IconButton>
                    </div>
                  </span>
                ):(
                  <span className="tw-text-string-base tw-whitespace-nowrap tw-flex tw-flex-row tw-items-center">
                    {deal.coupon} Off Coupon
                    <div onClick={handleOpenCouponDialog}>
                        <IconButton>
                            <QuestionIcon className='tw-w-question-icon tw-h-question-icon tw-items-start'/>
                        </IconButton>
                    </div>
                  </span>
                )}
                </>
              </div>
            </div>
          )}
          <div className="tw-hidden sm:tw-flex tw-gap-x-2">
            <Button
              component={Link}
              to={deal.affiliate_link ? deal.affiliate_link : deal.deal_link}
              target="_blank"
              fullWidth
              type="submit"
              variant="contained"
              sx={{
                color: 'white',
                textTransform: 'none',
                fontSize: '1.3rem',
                background:
                  'linear-gradient(90deg, #2D3EFE 0%, #429F97 44.5%, #A77EEA 99%)',
              }}
              endIcon={<KeyboardArrowRightRounded />}
              color="blue"
            >
              View The Deal
            </Button>
            <ToggleButton
              size="large"
              value="left"
              aria-label="left aligned"
              onClick={handleAddBookmark}
            >
              {bookmark !== null ? (
                // <Bookmark fontSize="large" color="primary" />
                <img
                  src="/bookmarkGradientFilledBlue.svg"
                  className="tw-h-8 tw-w-8"
                />
              ) : (
                <img
                  src="/bookmarkGradientBlue.svg"
                  className="tw-h-8 tw-w-8"
                />
                // <BookmarkBorderOutlined fontSize="large" color="primary" />
              )}
            </ToggleButton>
          </div>
          <div className="tw-hidden sm:tw-flex tw-justify-between">
            <div className="tw-flex tw-flex-col tw-items-center tw-gap-x-2 xl:tw-gap-x-4">
              <div
                className="tw-p-[2px] tw-rounded-md"
                style={{
                  background:
                    'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
                }}
              >
                <ToggleButtonGroup
                  value={upvoted ? 'Upvoted' : 'Downvoted'}
                  className="tw-gap-x-1"
                  onChange={handleVote}
                  exclusive
                >
                  <ToggleButton
                    value="upvoted"
                    className={`${
                      upvoted ? '!tw-bg-green-200' : '!tw-bg-surface-raised'
                    } !tw-border-none`}
                  >
                    {upvoted ? (
                      <ThumbUpTwoTone className="!tw-text-3xl !tw-text-green-700" />
                    ) : (
                      <ThumbUpOutlined className="!tw-text-3xl !tw-text-string-primary" />
                    )}
                  </ToggleButton>
                  <ToggleButton
                    value="downvoted"
                    className={`${
                      downvoted ? '!tw-bg-red-100' : '!tw-bg-surface-raised'
                    } !tw-border-none`}
                  >
                    {downvoted ? (
                      <ThumbDownTwoTone className="!tw-text-3xl !tw-text-string-secondary" />
                    ) : (
                      <ThumbDownOutlined className="!tw-text-3xl !tw-text-string-primary" />
                    )}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <span
                className="tw-text-base xl:tw-text-lg tw-font-medium"
                style={{
                  background:
                    'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
                  backgroundClip: 'text',
                  color: 'transparent',
                }}
              >
                Good deal?
              </span>
            </div>
            <div className="tw-flex tw-gap-x-4 tw-h-fit tw-text-sm tw-text-string-base">
              <div className="tw-flex tw-flex-col tw-items-center tw-text-string-primary tw-font-medium">
                <Checkbox
                  checked={expired !== null}
                  onClick={handleExpired}
                  icon={
                    <>
                      <img
                        className="tw-h-[31px] tw-p-0"
                        src="/expiredGradientBlue.svg"
                      />
                    </>
                  }
                  checkedIcon={
                    <img
                      src="/expiredGradientOrange.svg"
                      className="!tw-h-[31px] tw-p-0"
                    />
                  }
                />
                <span
                  className="tw-text-base xl:tw-text-lg tw-font-medium"
                  style={{
                    backgroundClip: 'text',
                    color: 'transparent',
                    backgroundImage:
                      expired !== null
                        ? 'linear-gradient(180deg, #FF5F5C 0%, #F49D23 100%)'
                        : 'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
                  }}
                >
                  Expired
                </span>
              </div>
              <div className="tw-flex tw-flex-col tw-items-center tw-text-string-primary tw-font-medium">
                <Checkbox
                  checked={report !== null}
                  onClick={handleReport}
                  size="medium"
                  icon={
                    <img
                      className="tw-h-[32px] tw-w-[32px] tw-p-0"
                      src="/reportGradientBlue.svg"
                    />
                    // <OutlinedFlagRounded className="!tw-text-3xl" style={{backgroundImage: 'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)', backgroundClip: 'text', color: 'transparent'}}/>
                  }
                  checkedIcon={
                    <img
                      className="tw-h-[32px] tw-w-[32px] tw-p-0"
                      src="/reportGradientYellow.svg"
                    />
                  }
                />
                <span
                  className="tw-text-base xl:tw-text-lg tw-font-medium"
                  style={{
                    backgroundClip: 'text',
                    color: 'transparent',
                    backgroundImage:
                      report !== null
                        ? 'linear-gradient(180deg, #8F8A00 0%, #E3B400 100%)'
                        : 'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
                  }}
                >
                  Report
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Carousel
        images={deal.uploaded_img_links}
        startingIndex={image}
        open={carousel}
        onClose={handleCarousel}
      />
       <CustomCouponDialog
          open={openCouponDialog}
          onClose={handleOpenCouponDialog}
          title={'Coupon'}
          description={
          'Coupons are limited time offers available on the deals page. To take advantage of a coupon, it must be applied at checkout. Please note that each customer is typically limited to one coupon. Promotions may end at any time and are valid while supplies last.'
          }
          content={<></>}
      />
      <CustomPromoDialog
          open={openPromoDialog}
          onClose={handleOpenPromoDialog}
          title={'Promotional Code'}
          description={
          'Promotional code is limited time offers by the seller for a specific product. Promotional code may or may not be displayed on the page of the deal. Promotional code can either be applied on the product page,  your online shopping cart, or the final stage of checkout. Websites may differ on where Promotional codes can be applied. Avoid spaces at the beginning or end of the Promotional code while entering unless otherwise noted.'
          }
          content={<></>}
      />
      <CustomDialog
        open={share}
        onClose={handleShare}
        title={'Share Deal'}
        description={
          'Share this exciting deal with your friends, family, and community.'
        }
        content={<Share deal_link={window.location.href} />}
      />
      <CustomExpiredDialog
        open={openExpiredDialog}
        onClose={handleOpenExpiredDialog}
        title={'Thank you for supporting our community in marking expired deals.'}
        description={
          'It has been successfully submitted, and we will review it shortly.'
        }
        content={<></>}
      />
      <CustomReportDialog
        open={openReportDialog}
        onClose={handleOpenReportDialog}
        title={'Thank you for supporting our community by reporting issues!'}
        description={
          'It has been successfully submitted, and we will review it shortly.'
        }
        content={<></>}
      />
      <BottomAction
        bookmark={bookmark}
        handleAddBookmark={handleAddBookmark}
        upvoted={upvoted}
        downvoted={downvoted}
        handleVote={handleVote}
        deal={deal}
      />
    </div>
  );
}

export default Deal;
