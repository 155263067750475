import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { listLightningDealsByTimeApi } from '../../api/deal';
// import { dealsByIsLightningDealAndUpdatedAt } from '../../api/deal';
import { cleanAndLowercase } from '../utils/cleanStringForSearch';


function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) != d.getTimezoneOffset();
}

function promo_alphabetically(ascending) {
  return function (a, b) {
    // equal items sort equally
    if (a.promotional_code === b.promotional_code) {
      return 0;
    }

    // nulls sort after anything else
    if (a.promotional_code === null) {
      return 1;
    }
    if (b.promotional_code === null) {
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return a.promotional_code < b.promotional_code ? -1 : 1;
    }

    // if descending, highest sorts first
    return a.promotional_code < b.promotional_code ? 1 : -1;
  };
}
/**
 * Handle sorting for price drop
 *
 * @param {bool} ascending - Whether the sorting is ascending or descending
 * @returns {bool} - if we're ascending, lower price_drop return -1. if we are descending, lower price_drop return 1.
 *
 * This method first check whether deal A and deal B have valid prev_price.
 * A valid prev_price means 1. have prev_price 2. prev_price > price.
 * If both A and B don't have valid prev_price, return 0;
 * If one of them have valid prev_price, it has larger price_drop
 * If both of the mhave valid prev_price, we compare which price_drop is larger
 *
 */
function price_drop_alphabetically(ascending) {
  return function (a, b) {
    // nulls sort after anything else
    if (a.prev_price === null) {
      if (b.prev_price === null) {
        return 0;
      } else if (b.prev_price < b.price) {
        return 0;
      }
      return 1;
    }
    if (b.prev_price === null) {
      if (a.prev_price < a.price) {
        return 0;
      }
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    let a_price_drop = (a.prev_price - a.price) / a.prev_price;
    let b_price_drop = (b.prev_price - b.price) / b.prev_price;
    if (ascending) {
      return a_price_drop < b_price_drop ? -1 : 1;
    }

    // if descending, highest sorts first
    return a_price_drop < b_price_drop ? 1 : -1;
  };
}

function hoursDifference(date) {
  const past = new Date(date); // posted time according to user current TimeFrame
  const now = new Date(); // Current EST TIME
  //console.log('now',now);


  let threeAM;

  if(isDST(now)){
    // + 4 UTC
    threeAM = new Date().setUTCHours(7,0,0,0); // !!! actual is 7
    let threeAMDate = new Date(threeAM);
    if(threeAMDate.getDate() !== now.getDate()){
      threeAM -= 24 * 60 * 60 * 1000;
    }
    threeAMDate = new Date(threeAM);
    // console.log('threeAMDST',threeAM, threeAMDate);
  }
  else{
    // + 5 UTC
    threeAM = new Date().setUTCHours(8,0,0,0); // !!! actual is 8
    let threeAMDate = new Date(threeAM);
    if(threeAMDate.getDate() !== now.getDate()){
      threeAM -= 24 * 60 * 60 * 1000;
    }
    threeAMDate = new Date(threeAM);
    // console.log('threeAM',threeAM, threeAMDate);
  }

  const secondsA = Math.floor((past - threeAM) / 1000);
  const minutesA = Math.floor(secondsA / 60);
  const hoursA = Math.floor(minutesA / 60);
  // console.log('past:', past)
  // console.log('hoursA: ', hoursA, minutesA, secondsA);

  if (hoursA < 0) {
    return 'deleted';
  } else {
    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    // console.log('hours: ',hours);
    if (hours >= 0 && hours < 3) { /// ! actual is 3
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }
    else{
      return 'expired';
    }
  }
}

function useListLightningDeals() {
  const [deals, setDeals] = useState([]);
  const [filter, setFilter] = useState({});
  const [selectedCount, setSelectedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [pageSize, setPageSize] = useState(300);
  const [DST,setDST] = useState(false);
  const [homeLightningDeals, setHomeLightningDeals] = useState([]);


  // lock to prevent getListDeals from being accessed simultaneously (racing condition for nextToken)
  const lockRef = useRef(null);
  const nextTokenRef = useRef(null);
  const filterRef = useRef({});

  const hoursDifferenceVal = (date) => {
    const past = new Date(date); // posted time according to user current TimeFrame
    const now = new Date(); // Current EST TIME
    //console.log('now',now);
    // console.log('Is DST:',DST);
    let threeAM;

    if(DST){
      // + 4 UTC
      threeAM = new Date().setUTCHours(7,0,0,0); // !!! actual is 7
      let threeAMDate = new Date(threeAM);
      if(threeAMDate.getDate() !== now.getDate()){
        threeAM -= 24 * 60 * 60 * 1000;
      }
      threeAMDate = new Date(threeAM);
      //console.log('threeAMDST',threeAM, threeAMDate);
    }
    else{
      // + 5 UTC
      threeAM = new Date().setUTCHours(8,0,0,0); // !!! actual is 8
      let threeAMDate = new Date(threeAM);
      if(threeAMDate.getDate() !== now.getDate()){
        threeAM -= 24 * 60 * 60 * 1000;
      }
      threeAMDate = new Date(threeAM);
      // console.log('threeAM',threeAM, threeAMDate);
    }


    const secondsA = Math.floor((past - threeAM) / 1000);
    const minutesA = Math.floor(secondsA / 60);
    const hoursA = Math.floor(minutesA / 60);
    //console.log('past:', past)
    // console.log('hoursA: ', hoursA, minutesA, secondsA);

    if (hoursA < 0) {
      return 'deleted';
    } else {
      const seconds = Math.floor((now - past) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      // console.log('hours: ',hours);
      if (hours >= 0 && hours < 3) { /// ! actual is 3
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      }
      else{
        return 'expired';
      }
    }
  }

  useEffect(() => {
    parseSearchParameters();
  }, [searchParameters]);

  // var nextToken;

  const getListDeals = async (loadMore = true) => {

    // console.log('Lightning!');
    if (lockRef.current) return;
    let filterQuery = createFilterQuery();
    lockRef.current = listLightningDealsByTimeApi(
      filterQuery,
      filterRef.current.q && filterRef.current.q.length > 0 ? 1000 : pageSize,
      // pageSize,
      loadMore === true ? nextTokenRef.current : null
    );
    const data = await lockRef.current;

    if (loadMore) {
      let filtered_lightning_deals = [];
      let home_lightning_deals = [];
      // dealsByDealTypeAndUpdatedAt
      data.data.dealsByIsLightningDealAndUpdatedAt.items.map(async (deal, indx) => {
        let expired_status = hoursDifferenceVal(deal.updatedAt);
        //console.log(expired_status);
        if (expired_status !== 'deleted') {
          filtered_lightning_deals.push(deal);
          if(expired_status !== 'expired'){
            home_lightning_deals.push(deal);
          }
        }
      });
      // setDeals((prevDeals) => [...prevDeals, ...data.data.dealsByDealTypeAndUpdatedAt.items]);
      setDeals((prevDeals) => [...prevDeals, ...filtered_lightning_deals]);
      setHomeLightningDeals((prevDeals) => [...prevDeals, ...home_lightning_deals]);
    } else {
      let filtered_lightning_deals = [];
      let home_lightning_deals = [];
      data.data.dealsByIsLightningDealAndUpdatedAt.items.map(async (deal, indx) => {
        let expired_status = hoursDifferenceVal(deal.updatedAt);
        // console.log(expired_status);
        if (expired_status !== 'deleted') {
          filtered_lightning_deals.push(deal);
          if(expired_status !== 'expired'){
            home_lightning_deals.push(deal);
          }
        }
      });
      // setDeals(data.data.dealsByDealTypeAndUpdatedAt.items);
      setDeals(filtered_lightning_deals);
      setHomeLightningDeals(home_lightning_deals);
    }

    // setNextToken(data.data.dealsByDate.nextToken);
    nextTokenRef.current = data.data.dealsByIsLightningDealAndUpdatedAt.nextToken;

    sortDeals(filter.sort_by);
    setSelectedCount(selectedFiltersCount(filter));
    lockRef.current = null;
  };

  const createFilterQuery = () => {
    const {
      free_shipping,
      instore,
      promo_code_included,
      coupon_included,
      price_min,
      price_max,
      dealer_types,
      forum_types,
      sub_categories,
      q,
    } = filterRef.current;
    // console.log('Lightning:', filterRef.current);
    let filterQuery = {
      and: [
        {
          free_shipping: { ne: free_shipping === true ? false : null },
        },

        {
          instore: { eq: false },
        },

        {
          has_promotional_code:
            promo_code_included === true ? { eq: true } : { ne: null },
        },

        {
          has_coupon: coupon_included === true ? { eq: true } : { ne: null },
        },

      ],
    };

    if (!(price_min === price_max && price_min === 0)) {
      filterQuery.and.push({
        price: { ge: price_min },
      });
      filterQuery.and.push({
        price: { le: price_max },
      });
    }

    // console.log("forum_types:", forum_types);
    if (forum_types && forum_types.length > 0) {
      // console.log('1',filter);
      if (!filterQuery.or) {
        filterQuery.or = [];
      }
      filterQuery.or = filterQuery.or.concat(
        forum_types.map((forum_type) => ({
          forum_type: { contains: forum_type },
        }))
      );
    }
    // console.log("filterQuery1:", filterQuery);
    if (dealer_types && dealer_types.length > 0) {
      if (!filterQuery.and) {
        filterQuery.and = [];
      }
      let dealer_filters = [];
      dealer_filters = dealer_filters.concat(
        dealer_types.map((dealer_type) => ({
          dealer_type: { contains: dealer_type },
        }))
      );
      // console.log('dealer_filters', dealer_filters);
      filterQuery.and = filterQuery.and.concat({ or: dealer_filters });
    }

    if (sub_categories && sub_categories.length > 0) {
      if (!filterQuery.or) {
        filterQuery.or = [];
      }
      filterQuery.or = filterQuery.or.concat(
        sub_categories.map((sub) => ({
          sub_category: { contains: sub },
        }))
      );
      /*
      let sub_category_filters = [];
      sub_category_filters = sub_category_filters.concat(
        sub_categories.map((sub_category) => ({
          sub_category: { contains: sub_category },
        }))
      );
      filterQuery.and = filterQuery.and.concat({ or: sub_category_filters });
      */
    }

    if (q && q.length > 0) {
      filterQuery.and = filterQuery.and.concat(
        q.map((each_q) => ({
          or: [
            { title_lowercase: { contains: cleanAndLowercase(each_q) } },
            { description_lowercase: { contains: cleanAndLowercase(each_q) } },
            { forum_type_lowercase: { contains: cleanAndLowercase(each_q) } },
            { dealer_type_lowercase: { contains: cleanAndLowercase(each_q) } },
          ],
        }))
      );
    }
    // console.log('Filter query:', filterQuery);
    return filterQuery;
  };

  const sortDeals = (sort) => {
    // remove dup and sort
    setDeals((prevState) => {
      let prevStateClone = structuredClone(prevState);

      // Remove duplicates
      prevStateClone = prevStateClone.reduce((acc, current) => {
        const x = acc.findIndex((item) => item.id === current.id);
        if (x <= -1) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Sort
      if (sort === 'Alphabetically') {
        prevStateClone.sort((a, b) =>
          String(a.title).localeCompare(String(b.title))
        );
      }
      if (sort === 'Promo code included') {
        prevStateClone.sort(promo_alphabetically(false));
      }
      if (sort === 'Most Recent') {
        prevStateClone.sort((a, b) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
      }
      if (sort === 'Top Deals') {
        prevStateClone.sort(
          (a, b) => b.vote - b.down_vote - (a.vote - a.down_vote)
        );
      }
      if (sort === 'Percentage Off') {
        // prevStateClone.sort((a, b) => b.price_drop - a.price_drop);
        prevStateClone.sort(price_drop_alphabetically(false));
        // console.log(prevStateClone);
      }
      // if (sort === 'Trending Deals') {
      //   prevStateClone.sort((a, b) => b.trending_tag - a.trending_tag);
      // }

      return prevStateClone;
    });
  };

  const parseSearchParameters = () => {
    // console.log('filter before parsings', filter);
    const cur_date = new Date();
    let jan = new Date(cur_date.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(cur_date.getFullYear(), 6, 1).getTimezoneOffset();
    let isDST = Math.max(jan, jul) != cur_date.getTimezoneOffset();
    setDST(isDST);
    const parsedFilter = {
      free_shipping: searchParameters.get('free_shipping'),
      promo_code_included: searchParameters.get('promo_code_included'),
      coupon_included: searchParameters.get('coupon_included'),
      // instore: searchParameters.get('instore'),
      price_min: searchParameters.get('price_min'),
      price_max: searchParameters.get('price_max'),
      dealer_types: searchParameters.getAll('dealer_types'),
      forum_types: searchParameters.getAll('forum_types'),
      sub_categories: searchParameters.getAll('sub_categories'),
      sort_by: searchParameters.get('sort_by'),
      q: searchParameters.getAll('q'),
    };
    // console.log('parsed searchParamters', parsedFilter);
    Object.entries(parsedFilter).forEach(([key, value]) => {
      if ((key === 'free_shipping') | (key === 'instore')) {
        if (value === null || value !== 'true') {
          parsedFilter[key] = false;
        } else {
          parsedFilter[key] = true;
        }
      } else if (key === 'promo_code_included') {
        if (value === null || value !== 'true') {
          parsedFilter[key] = false;
        } else {
          parsedFilter[key] = true;
        }
      } else if (key === 'coupon_included') {
        if (value === null || value !== 'true') {
          parsedFilter[key] = false;
        } else {
          parsedFilter[key] = true;
        }
      } else if ((key === 'price_min') | (key === 'price_max')) {
        if (value === null) {
          parsedFilter[key] = 0;
        } else {
          parsedFilter[key] = parseInt(value);
        }
      } else if ((key === 'dealer_types') | (key === 'forum_types')  | (key === 'sub_categories')) {
        if (
          !Array.isArray(value) ||
          value.every((entry) => typeof entry !== 'string')
        ) {
          parsedFilter[key] = [];
        } else {
          parsedFilter[key] = value;
        }
      } else if (key === 'sort_by') {
        if (
          (value === null) |
          ![
            'Most Recent',
            'Top Deals',
            'Percentage Off',
            'Trending Deals',
            //'Alphabetically',
            'Promo code included',
          ].includes(value)
        ) {
          parsedFilter[key] = 'Most Recent';
        } else {
          parsedFilter[key] = value;
        }
      } else if (key === 'q') {
        parsedFilter[key] = value;
      }
    });
    // console.log('parsedFilter: ', parsedFilter);
    setFilter(structuredClone(parsedFilter));
  };

  const selectedFiltersCount = (filter) => {
    let count = 0;

    if (filter.promo_code_included === true) {
      count++;
    }
    if (filter.coupon_included === true) {
      count++;
    }
    if (filter.free_shipping === true) {
      count++;
    }
    /*
    if (filter.instore === true) {
      count++;
    }*/
    if (filter.price_min > 0) {
      count++;
    }
    if (filter.price_max > 0) {
      count++;
    }

    count += filter.dealer_types?.length + filter.forum_types?.length + filter.sub_categories?.length;

    return count;
  };

  const updateSearchParameters = (filter) => {
    // console.log('search parameter before update', filter);
    Object.entries(filter).forEach(([key, value]) => {
      // free_shipping, instore, price_min, price_max, dealer_types, forum_types
      if (
        (value === false) |
        (value === 0) |
        (Array.isArray(value) && value.length === 0)
      ) {
        delete filter[key];
      }
    });
    setSearchParameters(filter);
    // console.log('updated search paramter', filter);
  };

  useEffect(() => parseSearchParameters(),
  []);

  useEffect(() => {
    filterRef.current = filter;
    const asyncFunction = async () => {
      updateSearchParameters(structuredClone(filter));
      await getListDeals(false);
      setLoading(false);
    };

    if (Object.keys(filter).length > 0) {
      asyncFunction();
    }
  }, [filter]);

  return {
    deals,
    homeLightningDeals,
    filter,
    loading,
    selectedCount,
    setFilter,
    setLoading,
    sortDeals,
    selectedFiltersCount,
    updateSearchParameters,
    getListDeals,
    setPageSize,
    nextTokenRef,
  };
}

export default useListLightningDeals;
