import { getCommentsApi } from '../../api/comments.js';
import { useQuery } from '@tanstack/react-query';

function useComments(deal_id) {
  const queryKey = ['comment', deal_id];

  const {
    data: comments,
    isLoading,
    error,
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      let comments = await getCommentsApi(deal_id);
      comments.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      return comments;
    },
    enabled: true,
    placeholderData: [],
    staleTime: 180000,
  });

  return {
    comments
  }
}

export default useComments;
