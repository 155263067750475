import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDealApi } from '../../api/deal';
import useListTopVotedDeals from '../apiHooks/useListTopVotedDeals';
import VerticalPostGrid from '../bookmarks/VerticalPostGrid';
import Section from '../home/Section';
import CustomizedDialog from '../utils/CustomizedDialog';
import Comments from './Comments';
import Deal from './Deal';

function DealDetails_() {
  const otherDeals = [];
  const [isAdmin, setIsAdmin] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);
  const navigate = useNavigate();
  const [dialogInfo, setDialogInfo] = useState('');

  const { topVotedDeals, loading } = useListTopVotedDeals();
  const { id } = useParams();
  const [deal, setDeal] = useState(null);

  const getDeal = async () => {
    setDeal(await getDealApi(id));
  };

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 150);
  }, []);

  // getDeal();
  useEffect(() => {
    getDeal();
  }, [id]);

  useEffect(() => {
    // auto redirection from email
    const queryParams = new URLSearchParams(window.location.search);
    const source = queryParams.get('source');

    if (deal === null) {
      return;
    }

    if (deal.expired === true) {
      setDialogInfo('This deal has expired :(');
      return;
    }

    if (source !== 'email') {
      return;
    }

    queryParams.delete('source');

    // delete source param to avoid auto redirect again when back to this page
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${queryParams.toString()}`
    );

    if (deal.has_promotional_code === true) {
      setDialogInfo('Please copy the promo code and proceed to the deal :)');
      return;
    }

    setTimeout(() => {
      const url = new URL(deal.affiliate_link || deal.deal_link);
      window.location.href = url.origin + url.pathname;
    }, 1000);
  }, [deal]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((User) => {
        const groups =
          User.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = User.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('admin')) {
          setIsAdmin(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Section
        content={
          <div className="tw-w-full">
            <div className="tw-w-full">
              <Deal />
              <div className="tw-flex tw-gap-x-5 tw-pt-8">
                <div className="tw-w-full lg:tw-w-[100%]">
                  <Comments />
                </div>
                {/* <div className="tw-hidden tw-w-0 lg:tw-w-[30%] lg:tw-block">
                  {otherDeals && <YouMayLike />}
                </div> */}
              </div>
            </div>
          </div>
        }
      />

      {!loading &&
        topVotedDeals.length >
        (
          <Section
            name="Current Deals"
            title="Related Products"
            content={<VerticalPostGrid posts={topVotedDeals.slice(4, 8)} />}
          />
        )}
      {(isAdmin || (deal && deal.poster_id === cognitoSub)) && (
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          onClick={() => {
            const pathArray = window.location.pathname.split('/');
            const lastSegment = pathArray.pop() || pathArray.pop(); // handle potential trailing slash
            navigate(`/DealEditor?id=${lastSegment}`);
          }}
          style={{ position: 'fixed', bottom: 100, right: 60 }}
        >
          <EditIcon />
        </Fab>
      )}
      <CustomizedDialog dialogInfo={dialogInfo} setDialogInfo={setDialogInfo} />
    </div>
  );
}

export default DealDetails_;
