import {
  Box,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DealEditorSocalAccountsSelector } from './DealEditorSocalAccountsSelector';

export function DealEditorSocalManager({ dealDetails, setDealDetails }) {
  const [isSocialManager, setIsSocialManager] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);

  useEffect(() => {
    console.log('dealDetails.dealType: ', dealDetails.dealType);
    if (isSocialManager && dealDetails.dealType === 'lightning') {
      const updatedSocialsAccounts = new Set(dealDetails.socials_accounts);
      updatedSocialsAccounts.add('LordOfDiscounts@Twitter');
      updatedSocialsAccounts.add('LordOfDiscounts@Threads');

      setDealDetails({
        ...dealDetails,
        socials_accounts: Array.from(updatedSocialsAccounts),
      });
    }
  }, [dealDetails.dealType]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = user.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('social-manager')) {
          setIsSocialManager(true);
          setDealDetails({
            ...dealDetails,
            socials_delay: 'zero_min_delay',
            socials_template: 'RegularLinkCreation',
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleRadioChange = (evt) => {
    setDealDetails({
      ...dealDetails,
      socials_delay: evt.target.value,
    });
  };

  return (
    isSocialManager && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" className="tw-text-string-base">
              {'Social Media Manager'}
            </Typography>
          </Grid>{' '}
          <Grid item xs={12} md={3} lg={3} className="tw-text-string-base">
            <Box display="start" flexDirection="column">
              <Box
                sx={{ mt: 1, minWidth: 200 }}
                display="start"
                flexDirection="row"
              >
                <FormLabel component="legend">Post on social media</FormLabel>
                <RadioGroup
                  value={dealDetails.socials_delay}
                  onChange={handleRadioChange}
                >
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Don't Post"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value="zero_min_delay"
                      control={<Radio />}
                      label="Immediately"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value="five_min_delay"
                      control={<Radio />}
                      label="After 5 mins"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value="ten_min_delay"
                      control={<Radio />}
                      label="After 10 mins"
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <DealEditorSocalAccountsSelector
              dealDetails={dealDetails}
              setDealDetails={setDealDetails}
              inStorePickup={dealDetails.instore}
            />
          </Grid>
        </Grid>
      </>
    )
  );
}

export default DealEditorSocalManager;
