import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { twitterTemplateMap } from '../constants/SocialMeidaTemplate';

export function DealEditorSocalTemplate({ dealDetails, setDealDetails }) {
  const [isSocialManager, setIsSocialManager] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = user.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('social-manager')) {
          setIsSocialManager(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleTemplateChange = async (templateKey) => {
    const templateValue = twitterTemplateMap[templateKey];
    const calculateDiscountPercentage = (price, prevPrice) => {
      if (!price || !prevPrice || prevPrice <= 0) return '';
      const discount = ((prevPrice - price) / prevPrice) * 100;
      return discount.toFixed(0); // Return discount percentage with 2 decimal places
    };

    const discountPercentage = calculateDiscountPercentage(
      dealDetails.price,
      dealDetails.prev_price
    );

    const filledTemplate = templateValue
      .replace('{title}', dealDetails.title || '____')
      .replace('{dealer_type}', dealDetails.dealer_type || '____')
      .replace(
        '{discountPercentage}',
        isNaN(discountPercentage) ? '' : discountPercentage
      )
      .replace('{promoCode}', dealDetails.promotional_code || '____')
      .replace('{coupon}', dealDetails.coupon || '____')
      // .replace('{link}', dealDetails.deal_link)
      .replace('{price}', dealDetails.price || '____')
      .replace('{descritption}', dealDetails.description || '____');

    setDealDetails({
      ...dealDetails,
      socials_template: templateKey,
      socials_twitter_content: filledTemplate,
      socials_threads_content: filledTemplate,
      socials_discord_content: filledTemplate.replace('#LRDeal | #AD', ''),
    });
  };

  return (
    isSocialManager && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12} xl={12} sx={{ mt: 0 }}>
            <FormControl sx={{ mt: 2, minWidth: 200 }}>
              <InputLabel id="template-select-label">Template</InputLabel>
              <Select
                labelId="template-select-label"
                id="template-select"
                value={
                  Object.keys(twitterTemplateMap).includes(
                    dealDetails.socials_template
                  )
                    ? dealDetails.socials_template
                    : ''
                }
                onChange={(evt) => {
                  const templateKey = evt.target.value;
                  handleTemplateChange(templateKey);
                }}
                label="Template"
              >
                {Object.keys(twitterTemplateMap).map((templateKey) => (
                  <MenuItem key={templateKey} value={templateKey}>
                    {templateKey}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12} sx={{ mt: 0 }}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Template"
                variant="outlined"
                value={dealDetails.socials_twitter_content}
                onChange={(e) =>
                  setDealDetails({
                    ...dealDetails,
                    socials_twitter_content: e.target.value,
                    socials_threads_content: e.target.value,
                    socials_discord_content: e.target.value,
                  })
                }
                fullWidth
                multiline
                rows={10}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  );
}

export default DealEditorSocalTemplate;
