import { API, graphqlOperation } from 'aws-amplify';
import * as React from 'react';
import { useState } from 'react';
import { useAppContext_ } from '../../AppContextProvider';
import { onCreateGlobalMessages } from '../../graphql/subscriptions';
import { NotificationHandler } from './NotificationHandler';

export function NotificationListener() {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState(null);
  const [desktopPermission, setDesktopPermission] = useState(false);
  const { globalNotificationListenerEnabled } = useAppContext_();

  const subscriptionRef = React.useRef(null); // Create a ref to hold the subscription

  React.useEffect(() => {
    console.log('useEffect triggered with:', {
      globalNotificationListenerEnabled,
    }); // Log when useEffect is triggered

    if (globalNotificationListenerEnabled) {
      console.log('Desktop notification is enabled'); // Log when subscription is enabled
      requestNotificationPermission();
      subscriptionRef.current = API.graphql(
        graphqlOperation(onCreateGlobalMessages, {})
      ).subscribe({
        next: (notificationData) => {
          DisplayNotification(
            notificationData.value.data.onCreateGlobalMessages
          );
        },
        error: (error) => {
          console.warn('Notification Subscription error:', error);
        },
      });
    } else {
      console.log('Notification Subscription is not enabled'); // Log when subscription is not enabled
    }

    const DisplayNotification = (notiContent) => {
      console.log('Sending notification with content:', notiContent); // Log when a notification is being sent

      let img_link = 'https://www.lordofsavings.com/logo.png';
      let deal_link = 'https://www.lordofsavings.com/deals?sort_by=Most+Recent';
      let price = '';
      let orig_price = '';

      try {
        // Parse the body content
        const bodyContent = JSON.parse(notiContent.body);
        // Extract img_link and deal_link
        deal_link = `https://www.lordofsavings.com/deal-details/${bodyContent.id}`;
        price = Number.isInteger(bodyContent.price)
          ? `$${bodyContent.price}`
          : `$${bodyContent.price.toFixed(2)}`;
        orig_price = bodyContent?.prev_price
          ? Number.isInteger(bodyContent.prev_price)
            ? `$${bodyContent.prev_price}`
            : `$${bodyContent.prev_price.toFixed(2)}`
          : '';
        img_link = bodyContent.img_link;
      } catch (error) {
        console.error('failt to parse message: ', error);
        console.error('message body: ', notiContent.body);
      }

      // Prepare the notification content
      const notificationContent = {
        title: notiContent.title,
        img_link: img_link,
        link: deal_link,
        price: price,
        orig_price: orig_price,
      };

      setNotificationContent(notificationContent);
      setOpenNotification(true);
    };

    async function requestNotificationPermission() {
      if (!('Notification' in window)) {
        console.error('Your browser does not support Desktop Notification.');
        return;
      }

      // console.log('Asking Desktop Notification permission.');
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          console.log('Desktop Notification permission granted.');
          setDesktopPermission(true);
          // alert('Permission granted.');
        } else if (permission === 'denied') {
          console.error('Desktop Notification permission denied.');
          // alert('Permission denied.');
        }
      });
    }

    // Cleanup subscription on component unmount
    return () => {
      console.log('Unsubscribing from subscription'); // Log when unsubscribing
      subscriptionRef.current?.unsubscribe();
    };
  }, [globalNotificationListenerEnabled]);

  return (
    <>
      <NotificationHandler
        notificationContent={notificationContent}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        desktopPermission={desktopPermission}
      />
    </>
  );
}
