import {
    IconButton,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { ReactComponent as QuestionIcon } from '../../icons/question_icon.svg';
import CustomMapDialog from '../map/CustomMapDialog';

/**
 * Section component: A reusable component for displaying sections on the home page.
 *
 * @param {string} name - The name of the section.
 * @param {string} title - The title of the section.
 * @param {string} link - The link to see all items
 * @param {React.ReactNode} content - The content of the section, written in JSX.
 * @returns {React.ReactNode} - Returns the rendered section component.
 */
function SectionInstorePage({ name, startIcon, title, link, content }) {
    const [openInstoreDialog, setInstoreOpenDialog] = useState(false);

    const handleOpenInstoreDialog = async (alert) => {
        await setInstoreOpenDialog(!openInstoreDialog);
    };
    return (
    <div
        className="tw-w-full tw-gap-y-4 tw-flex tw-flex-col lg:tw-gap-y-8 tw-transition-all
        tw-py-4 tw-px-[3px]
        sm:tw-py-4 sm:tw-px-8
        md:tw-py-4 md:tw-px-16
        lg:tw-py-4 lg:tw-px-20
        xl:tw-py-8 xl:tw-px-24"
        >
        {/* All 3 variables must be defined in order to show this section */}
        <div className="tw-px-2 md:tw-px-0 tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8">
            {name && (
            <div className="tw-flex tw-gap-x-4 tw-items-center">
                <div className="tw-w-3 md:tw-w-6 tw-h-9 md:tw-h-12 tw-rounded-md tw-bg-surface-primary"></div>
                <h1 className="tw-m-0 tw-text-xs md:tw-text-base tw-text-string-primary tw-font-semibold">
                    {name}
                </h1>
            </div>
            )}

            {title && (
            <div className="tw-flex tw-justify-start">
                <h2 className="tw-text-string-base tw-text-xl md:tw-text-4xl tw-font-semibold tw-flex tw-items-center tw-gap-6">
                    {startIcon && typeof startIcon === 'string' ? (
                        <img src={startIcon} />
                    ) : (
                        startIcon
                    )}
                    {title}
                </h2>
                <div onClick={handleOpenInstoreDialog}>
                    <IconButton>
                        <QuestionIcon/>
                    </IconButton>
                </div>
            </div>
            )}
        </div>

        <div className="tw-flex tw-gap-6 tw-justify-center tw-items-center">
            {content}
        </div>
        <CustomMapDialog
            open={openInstoreDialog}
            onClose={handleOpenInstoreDialog}
            title={'In-Store Only'}
            description={
            'Deals In-store only deals can only be found in the brick and mortar store that is offering the deal. At times the online listing of the deal may display the in-store only price for that item. Stores may differ by price and/or stock, your mileage may vary. Make sure to always price check everything in-store when looking for deals! A product may be marked a price, but ring up a completely different price at checkout. Always let us know what crazy deals your able to find on your trips out!'
            }
            content={<></>}
        />
    </div>
    );
}

export default SectionInstorePage;